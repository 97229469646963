import React, { Component } from 'react';
import { Row, Col, Accordion, Card, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import Moment from 'moment';
import { classActions } from '../../../_actions';
import Header from '../Shared/Header';
import { CSVLink } from "react-csv";
import './ClassesPage.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from 'react-router-dom';

  
class ClassesExam extends Component {
  componentWillMount() {
    this.props.getClasseDetail(this.props.match.params.classId, this.props.match.params.teacherId);
  }

  studentResults(classData) {
    let data = [
      ['Class Name', 'Student Code', 'Section Name(First Level)', 'Section Name(Second Level)', 'Section Name(Third Level)', 'Start Time', 'End Time', 'Count Click', 'Item Title', 'Answer', 'Score', 'Reverse']
    ];

    for (let index = 0; index < classData.classData.students.length; index++) {
      const element = classData.classData.students[index];
      for (let i = 0; i < element.StudentSections.length; i++) {
        const studentSection = element.StudentSections[i];
        for (let j = 0; j < studentSection.studentAnswers.length; j++) {
          const studentAnswer = studentSection.studentAnswers[j];
          let answer = '';
          if(studentAnswer.item.typeItem.typeItem === "closed answer question")
          {
            answer = studentAnswer.answer.answer;
          } else if(studentAnswer.item.typeItem.typeItem === "likert" || studentAnswer.item.typeItem.typeItem === "scale")
          {
            answer = studentAnswer.value;
          } else {
            answer = studentAnswer.text;
          }
          let clickCount = 0;
          let startTime = '';
          let endTime = '';
          if(studentSection.log)
          {
            let log = JSON.parse(studentSection.log);
            startTime = Moment(log.startTime).format('DD/MM/YYYY HH:mm:ss');
            endTime = Moment(log.endTime).format('DD/MM/YYYY HH:mm:ss');
            if(log.job)
            {
              clickCount = log.job.length;
            }
          }
          data.push([classData.classData.name, element.code, studentSection.section.parentLevelSection.parentLevelSection.section, studentSection.section.parentLevelSection.section, studentSection.section.section, startTime, endTime, clickCount, studentAnswer.item.title, answer, studentAnswer.score, studentAnswer.item.reverse]);
        }
      }
    }
    return data;
  }

  cardId(section) {
    if(section === 'Assessment') {
      return 'red-text';
    } else if(section === 'Training') {
      return 'purpal-text';
    } else if(section === 'Assessment check') {
      return 'blue-text';
    } else if(section === 'Working area') {
      return 'green-text';
    }
    return 'red-text';
  }

  render() {
    const classDetail = this.props.class;
    const now = Moment().format('YYYY-MM-DD');
    const classId = this.props.match.params.classId;
    const teacherId = this.props.match.params.teacherId;
    return (
      <div className="teacher-dash-main">
        <Header />
        <div className="teacher-dash-inner">
          <div className="teacher-classes-main">
            {classDetail.loading && 
              <>
                <em className="text-center">Loading...</em>
              </>
            }
            {classDetail.items && 
              <>
                <Row className="tchr-exam-row1">
                  <Col xs={2} sm={2}>
                    <Link to={"/researcher/classes-page/"}><img src="/back-arrow.png" alt="back-arrow" className='img-fluid'/></Link>
                  </Col>
                  <Col xs={10} sm={8}>
                    <h1 className="pg-ttl" style={{textAlign: "center"}}>{classDetail.items.classData.name} - {classDetail.items.teacher.school.school}</h1>
                  </Col>
                  <Col xs={12} sm={2}>
                    <CSVLink className="btn-default" data={this.studentResults(classDetail.items)} download="Students Result.csv">Download results</CSVLink>
                  </Col>
                </Row>
                <Row className="tchr-exam-row2">
                  <Accordion defaultActiveKey="0">
                    {classDetail.items.menu && classDetail.items.menu.map((menu, index) =>
                      <Card className="classes-exam-tstmain">
                        <Card.Header id={this.cardId(menu.section)}>
                          <Accordion.Toggle as={Button} variant="link" eventKey={`${index}`}> 
                            <span className="stdcrs-ttlicon"><img src={`/images/section_${menu.sectionId}.svg`} alt={menu.section}/></span>
                            <h2>{menu.section} 
                            {menu.scheduledOpening &&
                              <>
                                {(now < menu.scheduledOpening) && 
                                  <>
                                    <span className="avaicls">available from <span className="avai-datecls">{Moment(menu.scheduledOpening).format('DD/MM/YYYY')}</span></span>
                                  </>
                                }
                                {(now === menu.scheduledOpening) && 
                                  <>
                                    <span className="avaicls">available</span>
                                  </>
                                }
                              </>
                            }
                            </h2> 
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey={`${index}`}>
                          <ul className="exmcls-mainmenu">
                            {menu.secondLevel && menu.secondLevel.map((secondMenu, index) =>
                              <li><Link to="#">{secondMenu.section}</Link>
                                <ul className="exmcls-submenu">
                                  {secondMenu && secondMenu.thirdLevel.map((thirdMenu, index) =>
                                    <li>
                                      <label className="control control--checkbox"><input type="checkbox" checked={(thirdMenu.calendar && thirdMenu.calendar.activated === 1) ? true : false} disabled/><div className="control__indicator"></div>
                                      <Link to={`/researcher/classes-state/${teacherId}/${classId}/${thirdMenu.sectionId}`}>{thirdMenu.section} <span>({thirdMenu.noOfStudentAttempt}/{classDetail.items.classTot})</span></Link></label>
                                    </li>
                                  )}
                                </ul>
                              </li>
                            )}
                          </ul>
                        </Accordion.Collapse>
                      </Card>
                    )}
                  </Accordion>
                </Row>
              </>
            }
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  class: state.classDetail,
  user: state.userAuth.user
})

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    getClasseDetail: (classId, teacherId) => dispatch(classActions.getClasseDetail(classId, teacherId))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ClassesExam)