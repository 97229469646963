import React, { Component } from 'react';
import { connect } from 'react-redux';
import { classActions } from '../../../_actions';
import Header from '../Shared/Header';
import { Row, Col, Accordion, Card, Button, Table } from 'react-bootstrap';
import { CSVLink } from "react-csv";
import Moment from 'moment';
import './ClassesPage.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from 'react-router-dom';
  
class ClassesPage extends Component {

  componentWillMount() {
    this.props.getClasses();
  }

  studentResults(classData) {
    let data = [
      ['Class Name', 'Student Code', 'Section Name(First Level)', 'Section Name(Second Level)', 'Section Name(Third Level)', 'Start Time', 'End Time', 'Count Click', 'Item Title', 'Answer', 'Score', 'Reverse']
    ];

    for (let index = 0; index < classData.students.length; index++) {
      const element = classData.students[index];
      for (let i = 0; i < element.StudentSections.length; i++) {
        const studentSection = element.StudentSections[i];
        for (let j = 0; j < studentSection.studentAnswers.length; j++) {
          const studentAnswer = studentSection.studentAnswers[j];
          let answer = '';
          if(studentAnswer.item.typeItem.typeItem === "closed answer question")
          {
            answer = studentAnswer.answer.answer;
          } else if(studentAnswer.item.typeItem.typeItem === "likert" || studentAnswer.item.typeItem.typeItem === "scale")
          {
            answer = studentAnswer.value;
          } else {
            answer = studentAnswer.text;
          }
          let clickCount = 0;
          let startTime = '';
          let endTime = '';
          if(studentSection.log)
          {
            let log = JSON.parse(studentSection.log);
            startTime = Moment(log.startTime).format('DD/MM/YYYY HH:mm:ss');
            endTime = Moment(log.endTime).format('DD/MM/YYYY HH:mm:ss');
            if(log.job)
            {
              clickCount = log.job.length;
            }
          }
          data.push([classData.name, element.code, studentSection.section.parentLevelSection.parentLevelSection.section, studentSection.section.parentLevelSection.section, studentSection.section.section, startTime, endTime, clickCount, studentAnswer.item.title, answer, studentAnswer.score, studentAnswer.item.reverse]);
        }
      }
    }
    return data;
  }

  render() {
    const classes = this.props.classes;
    return (
      <div className="teacher-dash-main">
        <Header />
        <div className="teacher-dash-inner">
          <div className="teacher-classes-main">
            <h1 className="pg-ttl" style={{textAlign: "center"}}>CLASSES</h1>
            <Accordion defaultActiveKey="0">
              {classes.items && classes.items.classes && 
                <>
                  {classes.items.teachers.map((teacher, i) =>
                    <div key={i}>
                      {classes.items.classes.map((classData, index) =>
                        <Card className="acc-cls-main" key={index}>
                          <Card.Header>
                            <Row>
                              <Col>
                                <Accordion.Toggle as={Button} separator={";"} filename={"student-result.csv"} variant="link" eventKey={`${index}`}> {teacher.school.school} - {classData.name} - <span className="teacherName">{teacher.name} {teacher.surname}</span></Accordion.Toggle>
                              </Col>
                              <Col>
                                <ul className="dcvc-btn">
                                  <li>
                                    <CSVLink download="Students Result.csv" data={this.studentResults(classData)}><img src="/Download-icon.png" alt="Download" className='img-fluid'/> Results download</CSVLink>
                                  </li>
                                  <li><Link to={`/researcher/classes-exam/${teacher.teacherId}/${classData.classId}`}><img src="/view-icon.png" alt="view" className='img-fluid'/> View Course</Link></li>
                                </ul>
                              </Col>
                            </Row>
                          </Card.Header>
                          <Accordion.Collapse eventKey={`${index}`}>
                            <Table responsive bordered>
                              <tbody>
                                {classData.students.map((studentData, ind) =>
                                  <tr key={ind}>
                                    <td>{studentData.name}</td>
                                    <td>{studentData.code}</td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </Accordion.Collapse>
                        </Card>
                      )}
                    </div>
                  )}
                </>
              }
            </Accordion>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  classes: state.classes,
  user: state.userAuth.user
})

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    getClasses: () => dispatch(classActions.getClasses())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ClassesPage)
