import React from 'react';
import { Field } from "formik";
import { Row, Col } from 'react-bootstrap';
import Moment from 'moment';

function Scale({ item, index, setFieldValue, isEditableForm, studentSections, values }) {

    const isSelectedAns = (value, itemId) => {
        if(studentSections && studentSections[0] && studentSections[0].studentAnswers)
        {
            let isMatch = false;
            for (let index = 0; index < studentSections[0].studentAnswers.length; index++) {
                const element = studentSections[0].studentAnswers[index];
                if(element.itemId === itemId && element.value === value)
                {
                    isMatch = true;
                    break;
                }
            }
            return isMatch;
        }
        return false;
    }

    return (
        <div className="student-course-like">
            <h5>{item.title}</h5>
            <div className="student-likebtn-row">
                <div className="student-likebtn-col">
                    <Row className="btn-border-draw">
                        {[...Array(item.typeItem.scale)].map((x, i) =>
                            <Col key={`answer_${item.itemId}_${i+1}`}>
                                <div className="likebtn-radio">
                                    {isEditableForm && 
                                        <Field type="radio" id={`answer_${item.itemId}_${i+1}`} name={`answers.${item.itemId}.value`} value={`${i+1}`} onClick={()=>{setFieldValue(`answers.${item.itemId}.itemId`, item.itemId); setFieldValue(`answers.${item.itemId}.item_type`, item.typeItem.typeItem);setFieldValue('logs.job.' + values.logs.job.length, {itemId: item.itemId, time: Moment().format('YYYY-MM-DD hh:mm:ss')})}} required/>
                                    }
                                    {!isEditableForm && 
                                        <Field type="radio" id={`answer_${item.itemId}_${i+1}`} name={`answers.${item.itemId}.${i}.value`} value={`${i+1}`} disabled checked={(isSelectedAns((i+1), item.itemId))}/>
                                    }
                                    <label htmlFor={`answer_${item.itemId}_${i+1}`} className="radio-label"></label>
                                </div>
                            </Col>
                        )}
                    </Row>
                    <Row className="btn-border-text">
                        {[...Array(item.typeItem.scale)].map((x, i) =>
                            <Col key={`label_${item.itemId}_${i+1}`}>
                                <label>{i+1}</label>
                            </Col>
                        )}
                    </Row>
                </div>
                {!isEditableForm && 
                    <span className="text-muted">{item.note}</span>
                }
            </div>
        </div>
    );
}

export { Scale };