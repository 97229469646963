import React from 'react';
import { Field } from "formik";
import Moment from 'moment';

function Number({ item, index, setFieldValue, isEditableForm, studentSections, values }) {

    const getText = (itemId) => {
        if(studentSections && studentSections[0] && studentSections[0].studentAnswers)
        {
            let text = '';
            for (let index = 0; index < studentSections[0].studentAnswers.length; index++) {
                const element = studentSections[0].studentAnswers[index];
                if(element.itemId === itemId)
                {
                    text = element.value;
                    break;
                }
            }
            
            return text;
        }
        return '';
    }

    return (
        <div className="form-group stdtcrs-wrkstrw2-cont">
            <label htmlFor={`answer_${item.itemId}`} className="frm-lbl">{item.title}</label>
            {isEditableForm && 
                <Field type="number" min="0" max="9999" className="form-control" id={`answer_${item.itemId}`} name={`answers.${item.itemId}.value`}  onFocus={()=>{setFieldValue(`answers.${item.itemId}.itemId`, item.itemId); setFieldValue(`answers.${item.itemId}.item_type`, item.typeItem.typeItem)}} onBlur={()=>{setFieldValue('logs.job.' + values.logs.job.length, {itemId: item.itemId, time: Moment().format('YYYY-MM-DD hh:mm:ss')})}} required></Field>
            }
            {!isEditableForm && 
                <>
                    <Field type="number" min="0" max="9999" className="form-control" id={`answer_${item.itemId}`} name={`answers.${item.itemId}.value`} value={`${getText(item.itemId)}`}></Field>
                    <span className="text-muted">{item.note}</span>
                </>
            }
        </div>
    );
}

export { Number };