import logo from '../../../_assets/img/logo-white.png';
import stumenuicon1 from '../../../_assets/img/assessment.png';
import React from 'react';
import './CourseInterface.css';
import { Container, Row, Col, Navbar, Nav } from 'react-bootstrap';
  
function App() {
    return (
      <div className="student-course-main">
        <div className="student-dash-main">
          <Navbar  variant="dark">
            <Container fluid>
            <Navbar.Brand href="/home"><img src={logo} alt="logo" /></Navbar.Brand>
            <Nav className="ml-auto">
              <Nav.Link href="#"><img src="/user-icon.png" alt="user-icon" /> PIAZZINI LAURA</Nav.Link>
              <Nav.Link href="#"><img src="/lock-icon.png" alt="lock-icon" /> LOGOUT</Nav.Link>
            </Nav>
            </Container>
          </Navbar>
          <div className="student-dash-inner">
            <div className="student-course-row1">
              <div className="stdcrs-col-left">
                <span className="stdcrs-ttlicon"><img src={stumenuicon1} alt="nice"/></span>
                <h2>ASSESSMENT - QUESTIONNAIRE <span>Beliefs</span></h2>
              </div>
              <div className="stdcrs-col-right">
                <a href={"/student/course-questions2/"}><img src="/back-arrow.png" alt="back-arrow" /></a>
              </div>
            </div>
            <div className="student-course-row2">
              <div className="student-course-like">
                <h5>Vaccines are the main medical breakthrough ever made by man</h5>
                <div className="student-likebtn-row">
                  <div className="student-likebtn-col student-likebtn-col1">
                    <p>Fully agree</p>
                  </div>
                  <div className="student-likebtn-col student-likebtn-col2">
                    <Row className="btn-border-draw">
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-1" name="radio" type="radio" />
                          <label htmlFor="radio-1" className="radio-label"></label>
                        </div>
                      </Col>
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-2" name="radio" type="radio" />
                          <label htmlFor="radio-2" className="radio-label"></label>
                        </div>
                      </Col>
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-3" name="radio" type="radio" />
                          <label htmlFor="radio-3" className="radio-label"></label>
                        </div>
                      </Col>
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-4" name="radio" type="radio" />
                          <label htmlFor="radio-4" className="radio-label"></label>
                        </div>
                      </Col>
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-5" name="radio" type="radio" />
                          <label htmlFor="radio-5" className="radio-label"></label>
                        </div>
                      </Col>
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-6" name="radio" type="radio" />
                          <label htmlFor="radio-6" className="radio-label"></label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="btn-border-text">
                      <Col>
                        <label>1</label>
                      </Col>
                      <Col>
                        <label>2</label>
                      </Col>
                      <Col>
                        <label>3</label>
                      </Col>
                      <Col>
                        <label>4</label>
                      </Col>
                      <Col>
                        <label>5</label>
                      </Col>
                      <Col>
                        <label>6</label>
                      </Col>
                    </Row>
                  </div>
                  <div className="student-likebtn-col student-likebtn-col3">
                    <p>Totally disagree</p>
                  </div>
                </div>
              </div>
              <div className="student-course-like">
                <h5>Vaccines cause autism</h5>
                <div className="student-likebtn-row">
                  <div className="student-likebtn-col student-likebtn-col1">
                    <p>Fully agree</p>
                  </div>
                  <div className="student-likebtn-col student-likebtn-col2">
                    <Row className="btn-border-draw">
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-11" name="radio1" type="radio" />
                          <label htmlFor="radio-11" className="radio-label"></label>
                        </div>
                      </Col>
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-12" name="radio1" type="radio" />
                          <label htmlFor="radio-12" className="radio-label"></label>
                        </div>
                      </Col>
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-13" name="radio1" type="radio" />
                          <label htmlFor="radio-13" className="radio-label"></label>
                        </div>
                      </Col>
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-14" name="radio1" type="radio" />
                          <label htmlFor="radio-14" className="radio-label"></label>
                        </div>
                      </Col>
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-15" name="radio1" type="radio" />
                          <label htmlFor="radio-15" className="radio-label"></label>
                        </div>
                      </Col>
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-16" name="radio1" type="radio" />
                          <label htmlFor="radio-16" className="radio-label"></label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="btn-border-text">
                      <Col>
                        <label>1</label>
                      </Col>
                      <Col>
                        <label>2</label>
                      </Col>
                      <Col>
                        <label>3</label>
                      </Col>
                      <Col>
                        <label>4</label>
                      </Col>
                      <Col>
                        <label>5</label>
                      </Col>
                      <Col>
                        <label>6</label>
                      </Col>
                    </Row>
                  </div>
                  <div className="student-likebtn-col student-likebtn-col3">
                    <p>Totally disagree</p>
                  </div>
                </div>
              </div>
              <div className="student-course-like">
                <h5>The covid was generated in a Chinese laboratory</h5>
                <div className="student-likebtn-row">
                  <div className="student-likebtn-col student-likebtn-col1">
                    <p>Fully agree</p>
                  </div>
                  <div className="student-likebtn-col student-likebtn-col2">
                    <Row className="btn-border-draw">
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-21" name="radio2" type="radio" />
                          <label htmlFor="radio-21" className="radio-label"></label>
                        </div>
                      </Col>
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-22" name="radio2" type="radio" />
                          <label htmlFor="radio-22" className="radio-label"></label>
                        </div>
                      </Col>
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-23" name="radio2" type="radio" />
                          <label htmlFor="radio-23" className="radio-label"></label>
                        </div>
                      </Col>
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-24" name="radio2" type="radio" />
                          <label htmlFor="radio-24" className="radio-label"></label>
                        </div>
                      </Col>
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-25" name="radio2" type="radio" />
                          <label htmlFor="radio-25" className="radio-label"></label>
                        </div>
                      </Col>
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-26" name="radio2" type="radio" />
                          <label htmlFor="radio-26" className="radio-label"></label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="btn-border-text">
                      <Col>
                        <label>1</label>
                      </Col>
                      <Col>
                        <label>2</label>
                      </Col>
                      <Col>
                        <label>3</label>
                      </Col>
                      <Col>
                        <label>4</label>
                      </Col>
                      <Col>
                        <label>5</label>
                      </Col>
                      <Col>
                        <label>6</label>
                      </Col>
                    </Row>
                  </div>
                  <div className="student-likebtn-col student-likebtn-col3">
                    <p>Totally disagree</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="student-course-row3" style={{textAlign: "right"}}>
              <input type="submit" value="Save" />
            </div>
          </div>
        </div>
      </div>
    );
}
export default App;