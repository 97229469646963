import logo from '../../../_assets/img/logo-white.png';
import stumenuicon1 from '../../../_assets/img/assessment.png';
import React from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import './CourseInterface.css';
  
function App() {
    return (
      <div className="student-course-main">
        <div className="student-dash-main">
          <Navbar  variant="dark">
            <Container fluid>
            <Navbar.Brand href="/home"><img src={logo} alt="logo" /></Navbar.Brand>
            <Nav className="ml-auto">
              <Nav.Link href="#"><img src="/user-icon.png" alt="user-icon" /> PIAZZINI LAURA</Nav.Link>
              <Nav.Link href="#"><img src="/lock-icon.png" alt="lock-icon" /> LOGOUT</Nav.Link>
            </Nav>
            </Container>
          </Navbar>
          <div className="student-dash-inner">
            <div className="student-course-row1">
              <div className="stdcrs-col-left">
                <span className="stdcrs-ttlicon"><img src={stumenuicon1} alt="nice"/></span>
                <h2>ASSESSMENT - QUESTIONNAIRE <span>Beliefs</span></h2>
              </div>
              <div className="stdcrs-col-right">
                <a href={"/student/course-questions/"}><img src="/back-arrow.png" alt="back-arrow" /></a>
              </div>
            </div>
            <div className="student-course-row2">
              <div className="stdcrs-left-cont">
                <div className="stdcrslft-cont-inner">
                  <h5>Anti covid vaccines</h5>
                  <p>Da quando, l’11 gennaio 2020, è stata pubblicata la sequenza genetica del virus SARS-CoV-2, scienziati, industrie e altre organizzazioni in tutto il mondo hanno collaborato per sviluppare il prima possibile vaccini sicuri ed efficaci contro il COVID-19.</p>
                  <p>Alcuni vaccini sono realizzati utilizzando la stessa tecnologia (o “piattaforma”) di vaccini attualmente in uso, altri sono realizzati utilizzando nuovi approcci oppure approcci utilizzati recentemente nello sviluppo di vaccini contro SARS e Ebola. L’obiettivo di tutti questi vaccini è quello di produrre una risposta immunitaria al fine di neutralizzare il virus e impedire l’infezione delle cellule. Le principali piattaforme utilizzate sono le seguenti:</p>
                  <p>Vaccini virali inattivati: prodotti coltivando il virus SARS-CoV-2 in colture cellulari e inattivandolo chimicamente.</p>
                  <p>Vaccini vivi attenuati: prodotti generando una versione geneticamente indebolita del virus che si replica in misura limitata, non causando la malattia ma inducendo risposte immunitarie simili a quelle indotte dall'infezione naturale.</p>
                  <p>Vaccini proteici ricombinanti: basati sulla proteina spike, o sulla receptor binding domain (RBD) o su particelle simili a virus (VLP).</p>
                  <p>Vaccini a vettore virale: tipicamente basati su un virus esistente (generalmente un adenovirus incompetente per la replicazione) che trasporta la sequenza del codice genetico che codifica per la proteina spike.</p>
                  <p>Vaccini a DNA: basati su plasmidi, modificati in modo da trasportare geni che codificano in genere per la proteina spike che viene poi prodotta nell'individuo vaccinato.</p>
                  <p>Vaccini a RNA: basati su RNA messaggero (mRNA) o un RNA autoreplicante che fornisce l'informazione genetica per la proteina spike.</p>
                </div>
              </div>
              <div className="stdcrs-right-ques">
                <div className="stdcrsrgt-ques-inner">
                  <h4>Questions</h4>
                  <div className="form-group">
                    <span className="qsn-label">How many types of vaccines have been produced?</span>
                    <div className="radio">
                      <input id="radio-1" name="radio" type="radio" />
                      <label htmlFor="radio-1" className="radio-label">1</label>
                    </div>
                    <div className="radio">
                      <input id="radio-2" name="radio" type="radio" />
                      <label  htmlFor="radio-2" className="radio-label">2</label>
                    </div>
                    <div className="radio">
                      <input id="radio-3" name="radio" type="radio" />
                      <label htmlFor="radio-3" className="radio-label">3</label>
                    </div>
                  </div>
                  <div className="form-group">
                    <span className="qsn-label">What is the goal of the vaccine?</span>
                    <div className="radio">
                      <input id="radio-11" name="radio" type="radio" />
                      <label htmlFor="radio-11" className="radio-label">Produce an immune response</label>
                    </div>
                    <div className="radio">
                      <input id="radio-12" name="radio" type="radio" />
                      <label  htmlFor="radio-12" className="radio-label">Do not produce an immune response</label>
                    </div>
                    <div className="radio">
                      <input id="radio-13" name="radio" type="radio" />
                      <label htmlFor="radio-13" className="radio-label">Learn Chinese</label>
                    </div>
                  </div>
                  <div className="form-group">
                    <span className="qsn-label">How many vaccines have been sold?</span>
                    <div className="radio">
                      <input id="radio-21" name="radio" type="radio" />
                      <label htmlFor="radio-21" className="radio-label">1</label>
                    </div>
                    <div className="radio">
                      <input id="radio-22" name="radio" type="radio" />
                      <label  htmlFor="radio-22" className="radio-label">2</label>
                    </div>
                    <div className="radio">
                      <input id="radio-23" name="radio" type="radio" />
                      <label htmlFor="radio-23" className="radio-label">3</label>
                    </div>
                  </div>
                  <div className="form-group">
                    <span className="qsn-label">How many vaccines have been sold?</span>
                    <div className="radio">
                      <input id="radio-31" name="radio" type="radio" />
                      <label htmlFor="radio-31" className="radio-label">1</label>
                    </div>
                    <div className="radio">
                      <input id="radio-32" name="radio" type="radio" />
                      <label  htmlFor="radio-32" className="radio-label">2</label>
                    </div>
                    <div className="radio">
                      <input id="radio-33" name="radio" type="radio" />
                      <label htmlFor="radio-33" className="radio-label">3</label>
                    </div>
                  </div>
                </div>
                <div className="stdcrslft-btn">
                   <input type="submit" value="Save" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
export default App;