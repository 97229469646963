import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from '../Shared/Header';

class Page404 extends Component {

  render() {
    return (
      <div className="student-course-main">
        <div className="student-dash-main">          
          <Header />
            <div className="student-dash-inner">
            <HelmetProvider>
              <main>
                <Helmet>
                    <title>Page Not Found</title>
                </Helmet>
                <div className="abt-part1-main">
                    <div className="container">
                      <div className="abt-part1-inner">
                        <h1>404 Page Not Found</h1>
                        <h2>We are sorry, but the page you requested was not found</h2>
                        <br />
                        <h6>You can try to <Link to="/home">Home</Link></h6>
                        <br />
                        <br />
                        <br />
                        <br />
                      </div>
                    </div>
                </div>
              </main>
            </HelmetProvider>
            </div>
        </div>
      </div>
    )
  }
}
export default Page404;