import React, { Component } from 'react';
import {Redirect, Switch, Route} from "react-router-dom";
import Page404 from "./Components/ErrorPages/Page404";
import LoginPage from './Components/Login/LoginPage';
import ClassesPage from './Components/ClassesPage/ClassesPage';
import ClassesExam from './Components/ClassesPage/ClassesExam';
import ClassesState from './Components/ClassesPage/ClassesState';
import ClassesExamCheck from './Components/ClassesPage/ClassesExamCheck';
import { PrivateRoute } from '../_components';
import './Front.css';

class Front extends Component {
  render() {
    const { match } = this.props;
    return (
      <div>
        {/* <Header /> */}
        <div>
          <Switch>
            <Redirect exact from={`${match.url}`} to={`${match.url}/login`}/>
            <Route path={`${match.url}/login`} component={LoginPage}/>
            <PrivateRoute path={`${match.url}/classes-page`} component={ClassesPage} roles={['Researcher']}/>
            <PrivateRoute path={`${match.url}/classes-exam/:teacherId/:classId`} component={ClassesExam} roles={['Researcher']}/>
            <PrivateRoute path={`${match.url}/classes-state/:teacherId/:classId/:sectionId`} component={ClassesState} roles={['Researcher']}/>
            <PrivateRoute path={`${match.url}/classes-examcheck/:classId/:sectionId/:studentId/:teacherId`} component={ClassesExamCheck} roles={['Researcher']}/>
            <Route path={`${match.url}/404`} component={Page404}/>
            <Route component={Page404}/>
          </Switch>
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Front;
