import logo from '../../../_assets/img/logo-white.png';
import stumenuicon1 from '../../../_assets/img/assessment.png';
import React from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import './CourseInterface.css';

  
function App() {
    return (
      <div className="student-course-main">
        <div className="student-dash-main">
          <Navbar  variant="dark">
            <Container fluid>
            <Navbar.Brand href="/home"><img src={logo} alt="logo" /></Navbar.Brand>
            <Nav className="ml-auto">
              <Nav.Link href="#"><img src="/user-icon.png" alt="user-icon" /> PIAZZINI LAURA</Nav.Link>
              <Nav.Link href="#"><img src="/lock-icon.png" alt="lock-icon" /> LOGOUT</Nav.Link>
            </Nav>
            </Container>
          </Navbar>
          <div className="student-dash-inner">
            <div className="student-course-row1">
              <div className="stdcrs-col-left">
                <span className="stdcrs-ttlicon"><img src={stumenuicon1} alt="nice"/></span>
                <h2>ASSESSMENT - QUESTIONNAIRE <span>Beliefs</span></h2>
              </div>
              <div className="stdcrs-col-right">
                <a href={"/student/course-questions2/"}><img src="/back-arrow.png" alt="back-arrow" /></a>
              </div>
            </div>
            <div className="student-course-row2">
              <div className="stdcrs-left-cont">
                <div className="stdcrslft-cont-inner">
                  <div className="stdcrslft-contvd-col">
                    <video width="100%" controls ><source src="/SampleVideo.mp4" type="video/mp4" /></video>
                  </div>
                  <div className="stdcrslft-contvd-col">
                    <video width="100%" controls ><source src="/SampleVideo.mp4" type="video/mp4" /></video>
                  </div>
                </div>
              </div>
              <div className="stdcrs-right-ques">
                <div className="stdcrsrgt-ques-inner">
                  <h4>Questions</h4>
                  <div className="form-group">
                    <span className="qsn-label">How many types of vaccines have been produced?</span>
                    <div className="radio">
                      <input id="radio-1" name="radio" type="radio" />
                      <label htmlFor="radio-1" className="radio-label">1</label>
                    </div>
                    <div className="radio">
                      <input id="radio-2" name="radio" type="radio" />
                      <label  htmlFor="radio-2" className="radio-label">2</label>
                    </div>
                    <div className="radio">
                      <input id="radio-3" name="radio" type="radio" />
                      <label htmlFor="radio-3" className="radio-label">3</label>
                    </div>
                  </div>
                  <div className="form-group">
                    <span className="qsn-label">What is the goal of the vaccine?</span>
                    <div className="radio">
                      <input id="radio-11" name="radio" type="radio" />
                      <label htmlFor="radio-11" className="radio-label">Produce an immune response</label>
                    </div>
                    <div className="radio">
                      <input id="radio-12" name="radio" type="radio" />
                      <label  htmlFor="radio-12" className="radio-label">Do not produce an immune response</label>
                    </div>
                    <div className="radio">
                      <input id="radio-13" name="radio" type="radio" />
                      <label htmlFor="radio-13" className="radio-label">Learn Chinese</label>
                    </div>
                  </div>
                  <div className="form-group">
                    <span className="qsn-label">How many vaccines have been sold?</span>
                    <div className="radio">
                      <input id="radio-21" name="radio" type="radio" />
                      <label htmlFor="radio-21" className="radio-label">1</label>
                    </div>
                    <div className="radio">
                      <input id="radio-22" name="radio" type="radio" />
                      <label  htmlFor="radio-22" className="radio-label">2</label>
                    </div>
                    <div className="radio">
                      <input id="radio-23" name="radio" type="radio" />
                      <label htmlFor="radio-23" className="radio-label">3</label>
                    </div>
                  </div>
                  <div className="form-group">
                    <span className="qsn-label">How many vaccines have been sold?</span>
                    <div className="radio">
                      <input id="radio-31" name="radio" type="radio" />
                      <label htmlFor="radio-31" className="radio-label">1</label>
                    </div>
                    <div className="radio">
                      <input id="radio-32" name="radio" type="radio" />
                      <label  htmlFor="radio-32" className="radio-label">2</label>
                    </div>
                    <div className="radio">
                      <input id="radio-33" name="radio" type="radio" />
                      <label htmlFor="radio-33" className="radio-label">3</label>
                    </div>
                  </div>
                </div>
                <div className="stdcrslft-btn">
                   <input type="submit" value="Save" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
export default App;