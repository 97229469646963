import React from 'react';
import { useSelector } from 'react-redux';
import logo from '../../../_assets/img/logo.png';
import { Navbar, Nav } from 'react-bootstrap';
  
function Header() {
    const user = useSelector(state => state.userAuth.user);
    const mystyle = {
      maxHeight: "100px",
      marginLeft: "auto"
    };

    return (
        <Navbar variant="dark" expand="lg">
          <Navbar.Brand href="/home"><img src={logo} alt="logo" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="ml-auto" style={mystyle} navbarScroll>
              <Nav.Link href="#" className="text-uppercase"><img src="/user-icon.png" alt={user.name} /> {user.name}</Nav.Link>
              <Nav.Link href="/researcher/login"><img src="/lock-icon.png" alt="Logout" /> LOGOUT</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
    );
}
export default Header;