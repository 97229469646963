import { sectionConstants } from '../_constants';

export function answer(state = {}, action) {
    switch (action.type) {
        case sectionConstants.ANSWERS_REQUEST:
            return {
                loading: true
            };
        case sectionConstants.ANSWERS_SUCCESS:
            return {
                items: action.answers
            };
        case sectionConstants.ANSWERS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}