import React, { Component } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { classActions } from '../../../_actions';
import Header from '../Shared/Header';
import './ClassesPage.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from 'react-router-dom';
  
class ClassesState extends Component {
  componentWillMount() {
    this.props.getStudentsBySection(this.props.match.params.sectionId);
    this.props.getSectionHeader(this.props.match.params.sectionId);
    this.props.getClassHeader(this.props.match.params.classId);
  }

  render() {
    const students = this.props.students;
    const sectionHeader = this.props.sectionHeader;
    const classHeader = this.props.classHeader;
    const classId = this.props.match.params.classId;
    const sectionId = this.props.match.params.sectionId;
    
    return (
      <div className="teacher-dash-main">
        <Header />
        <div className="teacher-dash-inner">
          <div className="teacher-classes-main">
            {students.loading && 
              <>
                <em className="text-center">Loading...</em>
              </>
            }
            {students.items && 
              <>
                <Row className="tchr-exam-row1">
                  <Col xs={2} sm={2}>
                    <Link to={`/teacher/classes-exam/${classId}`}><img src="/back-arrow.png" className='img-fluid' alt="Back"/></Link>
                  </Col>
                  <Col xs={10} sm={8}>
                    {classHeader.items && 
                      <h1 className="pg-ttl" style={{textAlign: "center"}}>{classHeader.items.className} - {classHeader.items.schoolName}</h1>
                    }
                  </Col>
                  <Col xs={12} sm={2}>
                  </Col>
                </Row>
                <div className="tchr-exam-row2">
                  {sectionHeader.items && 
                    <>
                      <div className={`tchr-exam-tblttl ${sectionHeader.items.parentLevelSection.parentLevelSection.section.toLowerCase().replace(' ', '_')}`}>
                          <span className="stdcrs-ttlicon"><img src={`/images/section_${sectionHeader.items.parentLevelSection.parentLevelSection.sectionId}.svg`} alt={sectionHeader.items.parentLevelSection.parentLevelSection.section}/></span><h2>{sectionHeader.items.parentLevelSection.parentLevelSection.section} - {sectionHeader.items.parentLevelSection.section} <span>{sectionHeader.items.section}</span></h2>
                      </div>
                    </>
                  }
                  <div className="tchr-exam-tblmain">
                      <Table responsive bordered>
                        <tbody>
                          {students.items.studentsSectionDetails && students.items.studentsSectionDetails.map((studentSection, index) =>
                            <tr key={index}>
                              <td>{studentSection.student.name}</td>
                              <td>{studentSection.student.code}</td>
                              <td>
                                {studentSection.studentSectionState.label === 'Completed' && 
                                  <p className="cls-complete text-uppercase">{studentSection.studentSectionState.label}</p>
                                }
                                {studentSection.studentSectionState.label !== 'Completed' && 
                                  <p className="cls-error text-uppercase">{studentSection.studentSectionState.label}</p>
                                }
                              </td>
                              <td><Link to={`/teacher/classes-examcheck/${classId}/${sectionId}/${studentSection.student.studentId}`}><img src="/eyeview-icon.png" alt="View" /></Link></td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  students: state.students,
  sectionHeader: state.sectionHeader,
  classHeader: state.classHeader,
  user: state.userAuth.user
})

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    getStudentsBySection: (sectionId) => dispatch(classActions.getStudentsBySection(sectionId)),
    getSectionHeader: (sectionId) => dispatch(classActions.getSectionHeader(sectionId)),
    getClassHeader: (classId) => dispatch(classActions.getClassHeader(classId))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ClassesState)