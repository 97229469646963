import React from 'react';
import { CourceMainContent } from './';
import { useSelector } from 'react-redux';

function WorkSheet({ worksheet, isEditableForm, values, studentSections, setFieldValue, buttons, loading, showConvergence, showDisableConvergence, showFeedback}) {
    const user = useSelector(state => state.userAuth.user);
    return (
        <>
            <div className="student-course-row2">
                <div className="stdtcrs-worksheet">
                    {worksheet.prospective && 
                        <div className="stdtcrs-worksheet-row1">
                            <CourceMainContent item={worksheet.prospective} index={0} setFieldValue={setFieldValue} showQuestion={true} isEditableForm={isEditableForm} studentSections={studentSections} showConvergence={showConvergence()} showDisableConvergence={showDisableConvergence} values={values} showFeedback={showFeedback}/>
                        </div>
                    }
                    
                    <div className="stdtcrs-worksheet-row2">
                        <div className="stdtcrs-wrkstrw2-hdr">
                            {worksheet.in_favour && 
                                <div className="stdtcrs-wrkstrw2-hdr-left">
                                    <h6>ARGOMENTAZIONE A FAVORE</h6>
                                </div>
                            }
                            {worksheet.opposite && 
                                <div className="stdtcrs-wrkstrw2-hdr-right">
                                    <h6>ARGOMENTAZIONE CONTRO</h6>
                                </div>
                            }
                        </div>
                        <div className="stdtcrs-wrkstrw2-cont">
                            {worksheet.in_favour && 
                                <div className="stdtcrs-wrkstrw2-cont-left">
                                    {worksheet.in_favour.map((item, index) =>
                                        <div className="worksheet_textarea" key={index}>
                                            <CourceMainContent item={item} index={index} setFieldValue={setFieldValue} showQuestion={true} isEditableForm={isEditableForm} studentSections={studentSections} showConvergence={showConvergence()} showDisableConvergence={showDisableConvergence} values={values} showFeedback={showFeedback}/>
                                        </div>
                                    )}
                                </div>
                            }
                            {worksheet.opposite &&
                                <div className="stdtcrs-wrkstrw2-cont-right">
                                    {worksheet.opposite.map((item, index) =>
                                        <div className="worksheet_textarea" key={index}>
                                            <CourceMainContent item={item} index={index} setFieldValue={setFieldValue} showQuestion={true} isEditableForm={isEditableForm} studentSections={studentSections} showConvergence={showConvergence()} showDisableConvergence={showDisableConvergence} values={values} showFeedback={showFeedback}/>
                                        </div>
                                    )}
                                </div>
                            }
                        </div>
                    </div>
                    {worksheet.summary && 
                        <div className="stdtcrs-worksheet-row1">
                            <CourceMainContent item={worksheet.summary} index={0} setFieldValue={setFieldValue} showQuestion={true} isEditableForm={isEditableForm} studentSections={studentSections} showConvergence={showConvergence()} showDisableConvergence={showDisableConvergence} values={values} showFeedback={showFeedback}/>
                        </div>
                    }
                </div>
            </div>
            {buttons && 
                <>
                    {buttons.map((button, index) =>
                    <div key={`btn_${index}`}> 
                        {(isEditableForm || showConvergence(true)) && (!user || (user.role.role !== "Teacher" && user.role.role !== "Researcher")) && !showFeedback && button.type === 'buttonConfirm' && 
                        <div className="student-course-row3" style={{textAlign: "right"}}>
                            <button type="submit" disabled={(loading) ? true : false}>{loading && <span className="spinner-border spinner-border-sm mr-1"></span>}{button.label}</button>
                        </div>
                        }
                    </div>
                    )}
                </>
            }
        </>
    );
}

export { WorkSheet };