/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import SweetAlert from 'react-bootstrap-sweetalert';
import Front from "./student/Front";
import FrontTeacher from "./teacher/Front";
import FrontResearcher from "./researcher/Front";
import HomePage from "./home/HomePage";
import { history } from './_helpers';
import {getVisibleAlert} from "./_reducers/alerts.reducer";
import { useSelector } from 'react-redux';

export default function Routes() {
    const alerts = useSelector(state => state.alerts);
    const visibleAlert = getVisibleAlert(alerts);
    return (
        <Router history={history}>
            <Switch>
                <Redirect exact from="/" to="/home"/>
                <Route exact path="/home" component={HomePage}/>
                <Route path="/student" component={Front}/>
                <Route path="/teacher" component={FrontTeacher}/>
                <Route path="/researcher" component={FrontResearcher}/>
            </Switch>
            {visibleAlert && <SweetAlert {...visibleAlert}>{visibleAlert.content}</SweetAlert>}
        </Router>
    );
}
