import React from 'react';
import { Field } from "formik";
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

function ItemConvergence({ item, setFieldValue, showDisableConvergence, studentSections, showFeedback }) {
    const user = useSelector(state => state.userAuth.user);

    const isSelectedAns = (value, itemId) => {
        if(studentSections && studentSections[0] && studentSections[0].studentAnswers)
        {
            let isMatch = false;
            for (let index = 0; index < studentSections[0].studentAnswers.length; index++) {
                const element = studentSections[0].studentAnswers[index];
                if(element.itemId === itemId && element.convergence === value)
                {
                    isMatch = true;
                    break;
                }
            }
            return isMatch;
        }
        return false;
    }

    const getText = (itemId) => {
        if(studentSections && studentSections[0] && studentSections[0].studentAnswers)
        {
            let text = '';
            for (let index = 0; index < studentSections[0].studentAnswers.length; index++) {
                const element = studentSections[0].studentAnswers[index];
                if(element.itemId === itemId)
                {
                    text = element.comment;
                    break;
                }
            }
            
            return text;
        }
        return '';
    }

    const getFeedback = (itemId) => {
        if(studentSections && studentSections[0] && studentSections[0].studentAnswers)
        {
            let text = false;
            for (let index = 0; index < studentSections[0].studentAnswers.length; index++) {
                const element = studentSections[0].studentAnswers[index];
                if(element.itemId === itemId)
                {
                    text = element.feedback;
                    break;
                }
            }
            
            return text;
        }
        return false;
    }

    return (
        <>
            {(item.expected_convergence === 1 || (item.expected_feedback === 1 && user)) && 
                <div className="item-convergence ssa-main">
                    <div className="student-course-like student-course-rating">
                        <h6 style={{textAlign: "left"}}>Autovaluazione dello studente</h6>
                        {item.expected_convergence === 1 && !user && 
                            <div className="student-likebtn-row">
                                <div className="student-likebtn-col">
                                    <Row className="btn-border-draw">
                                        <Col></Col>
                                        {[...Array(10)].map((x, i) =>
                                            <Col key={`answer_${item.itemId}_${i+1}`}>
                                                <div className="likebtn-radio">
                                                    {!showDisableConvergence &&
                                                        <Field type="radio" id={`answer_con_${item.itemId}_${i+1}`} name={`answers.${item.itemId}.convergence`} value={`${i+1}`} onClick={()=>{setFieldValue(`answers.${item.itemId}.itemId`, item.itemId); setFieldValue(`answers.${item.itemId}.item_type`, item.typeItem.typeItem)}} required/>
                                                    }
                                                    {showDisableConvergence &&
                                                        <Field type="radio" id={`answer_con_${item.itemId}_${i+1}`} name={`answers.${item.itemId}.${i}.convergence`} value={`${i+1}`} checked={(isSelectedAns((i+1), item.itemId))} disabled/>
                                                    }                                            
                                                    <label htmlFor={`answer_con_${item.itemId}_${i+1}`} className="radio-label"></label>
                                                </div>
                                            </Col>
                                        )}
                                        <Col></Col>
                                    </Row>
                                    <Row className="btn-border-text">
                                        <Col></Col>
                                        {[...Array(10)].map((x, i) =>
                                            <Col key={`label_${item.itemId}_${i+1}`}>
                                                <label>{i+1}</label>
                                            </Col>
                                        )}
                                        <Col></Col>
                                    </Row>
                                </div>
                            </div>
                        }
                        {item.expected_convergence === 1 && user && user.role.role && (user.role.role === "Teacher" || user.role.role === "Researcher") &&
                            <div className="stdn-rtng-comment">
                                <h4>Convergenza
                                {[...Array(10)].map((x, i) =>
                                    <React.Fragment key={i}>
                                        {(isSelectedAns((i+1), item.itemId)) && 
                                            <span>{(i+1)}</span>
                                        }
                                    </React.Fragment>
                                )}
                                </h4>
                                <p>{getText(item.itemId)}</p>
                            </div>
                        }
                    </div>
                    {item.expected_convergence === 1 && !user && 
                        <div className="form-group">
                            <label className="frm-lbl">Commento</label>
                            {!showDisableConvergence &&
                                <Field as="textarea" className="form-control" name={`answers.${item.itemId}.comment`}  required></Field>
                            }
                            {showDisableConvergence &&
                                <Field as="textarea" className="form-control" name={`answers.${item.itemId}.comment`} value={`${getText(item.itemId)}`}></Field>
                            }
                        </div>
                    }
                    {user && user.role.role && (user.role.role === "Teacher") && item.expected_feedback === 1 && (showFeedback || getFeedback(item.itemId)) &&
                        <div className="form-group">
                            <label className="frm-lbl">FEEDBACK</label>
                            {!showFeedback && getFeedback(item.itemId) && 
                                <Field as="textarea" className="form-control" name={`answers.${item.itemId}.feedback`} value={`${getFeedback(item.itemId)}`} disabled></Field>
                            }
                            {showFeedback && 
                                <Field as="textarea" className="form-control" name={`answers.${item.itemId}.feedback`} required></Field>
                            }
                        </div>
                    }
                    {((user && user.role.role && (user.role.role === "Researcher"))) && item.expected_feedback === 1 && getFeedback(item.itemId) &&
                        <div className="stdn-rtng-comment">
                            <h4>FEEDBACK</h4>
                            <p>{getFeedback(item.itemId)}</p>
                        </div>
                    }
                </div>
            }
        </>
    );
}

export { ItemConvergence };
