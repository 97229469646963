import { classConstants } from '../_constants';
import { classService } from '../_services';
import { showAlert } from '.';
import { history } from '../_helpers';

export const classActions = {
    getClasses,
    getClasseDetail,
    updateCalendar,
    getStudentsBySection,
    getSectionHeader,
    getClassHeader,
    getSectionDataById,
    postFeedback
};

function getClasses() {
    return dispatch => {
        dispatch(request());

        classService.getClasses()
            .then(
                classes => { 
                    dispatch(success(classes));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(showAlert({
                        type: 'danger',
                        title: 'Oops!',
                        content: error.toString(),
                        showCancel: false,
                    }));
                }
            );
    };

    function request(classes) { return { type: classConstants.CLASS_REQUEST, classes } }
    function success(classes) { return { type: classConstants.CLASS_SUCCESS, classes } }
    function failure(error) { return { type: classConstants.CLASS_FAILURE, error } }
}

function getClasseDetail(classId, teacherId) {
    return dispatch => {
        dispatch(request());

        classService.getClasseDetail(classId, teacherId)
            .then(
                classes => { 
                    dispatch(success(classes));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(showAlert({
                        type: 'danger',
                        title: 'Oops!',
                        content: error.toString(),
                        showCancel: false,
                    }));
                }
            );
    };

    function request(classes) { return { type: classConstants.CLASS_DETAIL_REQUEST, classes } }
    function success(classes) { return { type: classConstants.CLASS_DETAIL_SUCCESS, classes } }
    function failure(error) { return { type: classConstants.CLASS_DETAIL_FAILURE, error } }
}

function updateCalendar(classId, values) {
    return dispatch => {
        dispatch(request());

        classService.updateCalendar(classId, values)
            .then(
                calendar => { 
                    dispatch(success(calendar));
                    const onConfirm = () => {window.location.reload()};
                    dispatch(showAlert({
                        type: 'success',
                        title: 'Success',
                        content: 'Calendario aggiornato con successo',
                        showCancel: false,
                        onConfirm: onConfirm
                    }));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(showAlert({
                        type: 'danger',
                        title: 'Oops!',
                        content: error.toString(),
                        showCancel: false,
                    }));
                }
            );
    };

    function request(calendar) { return { type: classConstants.UPDATE_CALENDAR_REQUEST, calendar } }
    function success(calendar) { return { type: classConstants.UPDATE_CALENDAR_SUCCESS, calendar } }
    function failure(error) { return { type: classConstants.UPDATE_CALENDAR_FAILURE, error } }
}

function getStudentsBySection(sectionId) {
    return dispatch => {
        dispatch(request());

        classService.getStudentsBySection(sectionId)
            .then(
                students => { 
                    dispatch(success(students));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(showAlert({
                        type: 'danger',
                        title: 'Oops!',
                        content: error.toString(),
                        showCancel: false,
                    }));
                }
            );
    };

    function request(students) { return { type: classConstants.SECTION_STUDENTS_REQUEST, students } }
    function success(students) { return { type: classConstants.SECTION_STUDENTS_SUCCESS, students } }
    function failure(error) { return { type: classConstants.SECTION_STUDENTS_FAILURE, error } }
}

function getSectionHeader(sectionId) {
    return dispatch => {
        dispatch(request());

        classService.getSectionHeader(sectionId)
            .then(
                sectionHeader => { 
                    dispatch(success(sectionHeader));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(showAlert({
                        type: 'danger',
                        title: 'Oops!',
                        content: error.toString(),
                        showCancel: false,
                    }));
                }
            );
    };

    function request(sectionHeader) { return { type: classConstants.SECTION_HEADER_REQUEST, sectionHeader } }
    function success(sectionHeader) { return { type: classConstants.SECTION_HEADER_SUCCESS, sectionHeader } }
    function failure(error) { return { type: classConstants.SECTION_HEADER_FAILURE, error } }
}

function getClassHeader(classId) {
    return dispatch => {
        dispatch(request());

        classService.getClassHeader(classId)
            .then(
                classHeader => { 
                    dispatch(success(classHeader));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(showAlert({
                        type: 'danger',
                        title: 'Oops!',
                        content: error.toString(),
                        showCancel: false,
                    }));
                }
            );
    };

    function request(classHeader) { return { type: classConstants.CLASS_HEADER_REQUEST, classHeader } }
    function success(classHeader) { return { type: classConstants.CLASS_HEADER_SUCCESS, classHeader } }
    function failure(error) { return { type: classConstants.CLASS_HEADER_FAILURE, error } }
}

function getSectionDataById(sectionId, studentId) {
    return dispatch => {
        dispatch(request());

        classService.getSectionDataById(sectionId, studentId)
            .then(
                sectionData => { 
                    dispatch(success(sectionData));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(showAlert({
                        type: 'danger',
                        title: 'Oops!',
                        content: error.toString(),
                        showCancel: false,
                    }));
                }
            );
    };

    function request(sectionData) { return { type: classConstants.SECTION_DATA_REQUEST, sectionData } }
    function success(sectionData) { return { type: classConstants.SECTION_DATA_SUCCESS, sectionData } }
    function failure(error) { return { type: classConstants.SECTION_DATA_FAILURE, error } }
}

function postFeedback(answers) {
    return dispatch => {
        dispatch(request({ answers }));

        classService.postFeedback(answers)
            .then(
                res => {
                    const onConfirm = () => {history.push(`/teacher/classes-state/${answers.classId}/${answers.sectionId}`)};
                    dispatch(success(res));
                    dispatch(showAlert({
                        type: 'success',
                        title: 'Success',
                        content: res.message,
                        showCancel: false,
                        onConfirm: onConfirm
                    }));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(showAlert({
                        type: 'danger',
                        title: 'Oops!',
                        content: error.toString(),
                        showCancel: false,
                    }));
                }
            );
    };

    function request() { return { type:classConstants.FEEDBACK_REQUEST } }
    function success(answers) { return { type:classConstants.FEEDBACK_SUCCESS, answers } }
    function failure(error) { return { type:classConstants.FEEDBACK_FAILURE, error } }
}
