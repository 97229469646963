import React, { Component } from 'react';
import {Redirect, Switch, Route} from "react-router-dom";
import Page404 from "./Components/ErrorPages/Page404";
import MenuInterface from './Components/MenuInterface/MenuInterface';
import SubMenuInterface from './Components/MenuInterface/SubMenuInterface';
import CourseInterface from './Components/CourseInterface/CourseInterface';
import CourseInterface1 from './Components/CourseInterface/CourseInterface1';
import CourseInterface2 from './Components/CourseInterface/CourseInterface2';
import CourseInterface3 from './Components/CourseInterface/CourseInterface3';
import CourseInterface4 from './Components/CourseInterface/CourseInterface4';
import CourseInterface5 from './Components/CourseInterface/CourseInterface5';
import CourseInterface6 from './Components/CourseInterface/CourseInterface6';
import CourseInterface7 from './Components/CourseInterface/CourseInterface7';
import LoginPage from './Components/Login/LoginPage';
import { PrivateRoute } from '../_components';
import './Front.css';

class Front extends Component {
  render() {
    const { match } = this.props;
    return (
      <div>
        <div>
          <Switch>
            <Redirect exact from={`${match.url}`} to={`${match.url}/login`}/>
            <Route path={`${match.url}/login`} component={LoginPage}/>
            <PrivateRoute path={`${match.url}/menu`} component={MenuInterface} roles={['Student']}/>
            <PrivateRoute path={`${match.url}/submenu/:mainMenuId/:subMenuId`} component={SubMenuInterface} roles={['Student']}/>
            <PrivateRoute path={`${match.url}/course-questions/:sectionId`} component={CourseInterface} roles={['Student']}/>
            <Route path={`${match.url}/course-questions1`} component={CourseInterface1}/>
            <Route path={`${match.url}/course-questions2`} component={CourseInterface2}/>
            <Route path={`${match.url}/course-questions3`} component={CourseInterface3}/>
            <Route path={`${match.url}/course-questions4`} component={CourseInterface4}/>
            <Route path={`${match.url}/course-questions5`} component={CourseInterface5}/>
            <Route path={`${match.url}/course-questions6`} component={CourseInterface6}/>
            <Route path={`${match.url}/course-questions7`} component={CourseInterface7}/>
            <Route path={`${match.url}/404`} component={Page404}/>
            <Route component={Page404}/>
          </Switch>
        </div>
      </div>
    );
  }
}

export default Front;
