// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../_assets/img/Vector.svg";
import ___CSS_LOADER_URL_IMPORT_1___ from "../../../_assets/img/Vector2.svg";
import ___CSS_LOADER_URL_IMPORT_2___ from "../../../_assets/img/Vector3.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".training .stdcrslft-cont-inner h2 {color: #9185BD; }\n.training .stdcrslft-cont-inner ul li.acive {color: #9185BD; }\n.training .stdcrslft-cont-inner ul li:after {background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "); }\n.assessment_check .stdcrs-col-left h2 {color: #69ADE2; }\n.assessment_check .stdcrslft-cont-inner ul li.acive {color: #69ADE2; }\n.assessment_check .stdcrslft-cont-inner ul li:after {background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + "); }\n.working_area .stdcrs-col-left h2 {color: #A7D1AF; }\n.working_area .stdcrslft-cont-inner ul li.acive {color: #A7D1AF; }\n.working_area .stdcrslft-cont-inner ul li:after {background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + "); }\n.stdcrs-right-ques.full {width: 100%;padding: 0 15px;}", "",{"version":3,"sources":["webpack://src/student/Components/CourseInterface/CourseInterface.css"],"names":[],"mappings":"AAAA,oCAAoC,cAAc,EAAE;AACpD,6CAA6C,cAAc,EAAE;AAC7D,6CAA6C,yDAAwD,EAAE;AACvG,uCAAuC,cAAc,EAAE;AACvD,qDAAqD,cAAc,EAAE;AACrE,qDAAqD,yDAAyD,EAAE;AAChH,mCAAmC,cAAc,EAAE;AACnD,iDAAiD,cAAc,EAAE;AACjE,iDAAiD,yDAAyD,EAAE;AAC5G,yBAAyB,WAAW,CAAC,eAAe,CAAC","sourcesContent":[".training .stdcrslft-cont-inner h2 {color: #9185BD; }\n.training .stdcrslft-cont-inner ul li.acive {color: #9185BD; }\n.training .stdcrslft-cont-inner ul li:after {background-image: url('../../../_assets/img/Vector.svg'); }\n.assessment_check .stdcrs-col-left h2 {color: #69ADE2; }\n.assessment_check .stdcrslft-cont-inner ul li.acive {color: #69ADE2; }\n.assessment_check .stdcrslft-cont-inner ul li:after {background-image: url('../../../_assets/img/Vector2.svg'); }\n.working_area .stdcrs-col-left h2 {color: #A7D1AF; }\n.working_area .stdcrslft-cont-inner ul li.acive {color: #A7D1AF; }\n.working_area .stdcrslft-cont-inner ul li:after {background-image: url('../../../_assets/img/Vector3.svg'); }\n.stdcrs-right-ques.full {width: 100%;padding: 0 15px;}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
