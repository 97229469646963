import { authHeader } from '../_helpers';
import { studentService } from './';
const API_URL = process.env.REACT_APP_API_URL;
export const menuService = {
    getStudentMenu,
    getStudentSubMenu
};

function getStudentMenu(studentId, classId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${API_URL}/students/menu?classId=${classId}&studentId=${studentId}`, requestOptions).then(handleResponse);
}

function getStudentSubMenu(studentId, classId, fatherId, sectionId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${API_URL}/students/submenu?classId=${classId}&studentId=${studentId}&fatherId=${fatherId}&sectionId=${sectionId}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                studentService.logout();
                window.location.reload();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}