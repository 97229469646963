import { menuConstants } from '../_constants';

export function menu(state = {}, action) {
    switch (action.type) {
        case menuConstants.GET_STUDENT_MENU_REQUEST:
            return {
                loading: true
            };
        case menuConstants.GET_STUDENT_MENU_SUCCESS:
            return {
                items: action.menu
            };
        case menuConstants.GET_STUDENT_MENU_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}