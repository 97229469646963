import { sectionConstants } from '../_constants';

export function section(state = {}, action) {
    switch (action.type) {
        case sectionConstants.SECTION_REQUEST:
            return {
                loading: true
            };
        case sectionConstants.SECTION_SUCCESS:
            return {
                items: action.course
            };
        case sectionConstants.SECTION_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}