import { menuConstants } from '../_constants';
import { menuService } from '../_services';
import { showAlert } from '.';

export const menuActions = {
    getStudentMenu,
    getStudentSubMenu
};

function getStudentMenu(studentId, classId) {
    return dispatch => {
        dispatch(request());

        menuService.getStudentMenu(studentId, classId)
            .then(
                menu => dispatch(success(menu)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(showAlert({
                        type: 'danger',
                        title: 'Oops!',
                        content: error.toString(),
                        showCancel: false,
                    }));
                }
            );
    };

    function request() { return { type: menuConstants.GET_STUDENT_MENU_REQUEST } }
    function success(menu) { return { type: menuConstants.GET_STUDENT_MENU_SUCCESS, menu } }
    function failure(error) { return { type: menuConstants.GET_STUDENT_MENU_FAILURE, error } }
}

function getStudentSubMenu(studentId, classId, fatherId, sectionId) {
    return dispatch => {
        dispatch(request());

        menuService.getStudentSubMenu(studentId, classId, fatherId, sectionId)
            .then(
                submenu => dispatch(success(submenu)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: menuConstants.GET_STUDENT_SUBMENU_REQUEST } }
    function success(submenu) { return { type: menuConstants.GET_STUDENT_SUBMENU_SUCCESS, submenu } }
    function failure(error) { return { type: menuConstants.GET_STUDENT_SUBMENU_FAILURE, error } }
}