import { sectionConstants } from '../_constants';
import { courseService } from '../_services';
import { showAlert } from '.';
import { history } from '../_helpers';

export const courseActions = { getSectionDataById, postAnswers, postConvergence } 

function getSectionDataById(sectionId, studentId){
    return dispatch => {
        dispatch(request());

        courseService.getSectionDataById(sectionId, studentId   ) 
        .then(
            course => dispatch(success(course)),
            error => {
                dispatch(failure(error.toString()));
                dispatch(showAlert({
                    type: 'danger',
                    title: 'Oops!',
                    content: error.toString(),
                    showCancel: false,
                }));
            }
        );
    };

    function request() { return { type:sectionConstants.SECTION_REQUEST } }
    function success(course) { return { type:sectionConstants.SECTION_SUCCESS, course } }
    function failure(error) { return { type:sectionConstants.SECTION_FAILURE, error } }
}

function postAnswers(answers) {
    return dispatch => {
        dispatch(request({ answers }));

        courseService.postAnswers(answers)
            .then(
                res => {
                    if(res && res.status && res.status === 'redirect')
                    {
                        history.push(`/student/submenu/${res.mainmenuId}/${res.submenuId}`)
                    }
                    if(res && res.status && res.status === 'failed')
                    {
                        dispatch(success(res));
                        dispatch(showAlert({
                            type: 'danger',
                            title: 'Oops!',
                            content: res.message,
                            showCancel: false,
                        }));
                    }
                    else
                    {
                        const onConfirm = () => {history.push(`/student/course-questions/${answers.sectionId}`)};
                        dispatch(success(res));
                        dispatch(showAlert({
                            type: 'success',
                            title: 'Salvataggio effettuato',
                            content: res.message,
                            showCancel: false,
                            onConfirm: onConfirm
                        }));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(showAlert({
                        type: 'danger',
                        title: 'Oops!',
                        content: error.toString(),
                        showCancel: false,
                    }));
                }
            );
    };

    function request() { return { type:sectionConstants.ANSWERS_REQUEST } }
    function success(answers) { return { type:sectionConstants.ANSWERS_SUCCESS, answers } }
    function failure(error) { return { type:sectionConstants.ANSWERS_FAILURE, error } }
}

function postConvergence(answers) {
    return dispatch => {
        dispatch(request({ answers }));

        courseService.postConvergence(answers)
            .then(
                res => {
                    const onConfirm = () => {history.push(`/student/submenu/${res.mainmenuId}/${res.submenuId}`)};
                    dispatch(success(res));
                    dispatch(showAlert({
                        type: 'success',
                        title: 'Salvataggio effettuato',
                        content: res.message,
                        showCancel: false,
                        onConfirm: onConfirm
                    }));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(showAlert({
                        type: 'danger',
                        title: 'Oops!',
                        content: error.toString(),
                        showCancel: false,
                    }));
                }
            );
    };

    function request() { return { type:sectionConstants.ANSWERS_REQUEST } }
    function success(answers) { return { type:sectionConstants.ANSWERS_SUCCESS, answers } }
    function failure(error) { return { type:sectionConstants.ANSWERS_FAILURE, error } }
}
