import logo from '../_assets/img/logo-white.png';
import unifi from '../_assets/img/unifi.png';
import mathema from '../_assets/img/mathema.png';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import React from 'react';
import './HomePage.css';

function HomePage() {
  const loggedIn = useSelector(state => state.authentication.loggedIn);
  const userLoggedIn = useSelector(state => state.userAuth.loggedIn);
  const user = useSelector(state => state.userAuth.user);
  return (
    <div className="home-main">
      <div className="home-inner">
        <div className="home-left">
          <div className="home-left-logo">
            <img src={logo} alt="logo" className='img-fluid'/>
          </div>
          <div className="home-left-adlogo">
            <div className="hmlft-adlogo-col">
                <img src={unifi} alt="unifi" className='img-fluid'/>
            </div>
            <div className="hmlft-adlogo-col">
                <img src={mathema} alt="mathema" className='img-fluid'/>
            </div>
          </div>
        </div>
        <div className="home-right">
          <div className="home-right-inner">
            {loggedIn && 
              <Link to="/student/menu">STUDENTE</Link>
            }
            {!loggedIn && 
              <Link to="/student/login">STUDENTE</Link>
            }
            {!userLoggedIn && 
              <>
                <Link to="/teacher/login">INSEGNANTE</Link>
                <Link to="/researcher/login">RICERCATORE</Link>
              </>
            }
            {userLoggedIn && 
              <>
                {user && user.role.role === 'Teacher' && 
                  <Link to="/teacher/classes-page">INSEGNANTE</Link>
                }
                {user && user.role.role !== 'Teacher' && 
                  <Link to="/teacher/login">INSEGNANTE</Link>
                }
                {user && user.role.role === 'Researcher' && 
                  <Link to="/researcher/classes-page">RICERCATORE</Link>
                }
                {user && user.role.role !== 'Researcher' && 
                  <Link to="/researcher/login">RICERCATORE</Link>
                }
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
