import React, { Component } from 'react';
import { connect } from 'react-redux';
import { classActions } from '../../../_actions';
import Header from '../Shared/Header';
import { Row, Col, Accordion, Card, Button, Table } from 'react-bootstrap';
import { CSVLink } from "react-csv";
import './ClassesPage.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from 'react-router-dom';
  
class ClassesPage extends Component {
  
  componentWillMount() {
    this.props.getClasses();
  }

  studentCodes(students, classData) {
    let data = [
      ['Class Name', 'Name', 'Code']
    ];
    for (let index = 0; index < students.length; index++) {
      const element = students[index];
      data.push([classData.name, element.name, element.code]);
    }
    return data;
  }

  render() {  
    const classes = this.props.classes;
    return (
      <div className="teacher-dash-main">
        <Header />
        <div className="teacher-dash-inner">
          <div className="teacher-classes-main">
            {classes.loading && 
              <>
                <em className="text-center">Loading...</em>
              </>
            }
            {classes.items && classes.items.classes && 
              <>
                <h1 className="pg-ttl" style={{textAlign: "center"}}>CLASSI</h1>
                <Accordion defaultActiveKey="0">
                  {classes.items.classes.map((classData, index) =>
                    <Card className="acc-cls-main" key={index}>
                      <Card.Header>
                        <Row>
                          <Col>
                            <Accordion.Toggle as={Button} separator={";"} filename={"student-codes.csv"} variant="link" eventKey={`${index}`}> {classData.name} - {classes.items.teacher.school.school}</Accordion.Toggle>
                          </Col>
                          <Col>
                            <ul className="dcvc-btn">
                              <li>
                                <CSVLink download="Students Code.csv" data={this.studentCodes(classData.students, classData)}><img src="/Download-icon.png" alt="Download" className='img-fluid'/> Download codici</CSVLink>
                              </li>
                              <li><Link to={`/teacher/classes-exam/${classData.classId}`}><img src="/view-icon.png" alt="Accedi alla classe" className='img-fluid'/> Accedi alla classe</Link></li>
                            </ul>
                          </Col>
                        </Row>
                      </Card.Header>
                      <Accordion.Collapse eventKey={`${index}`}>
                        <Table responsive bordered>
                          <tbody>
                            {classData.students.map((studentData, ind) =>
                              <tr key={ind}>
                                <td>{studentData.name}</td>
                                <td>{studentData.code}</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </Accordion.Collapse>
                    </Card>
                  )}
                </Accordion>
              </>
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  classes: state.classes,
  user: state.userAuth.user
})

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    getClasses: () => dispatch(classActions.getClasses())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ClassesPage)
