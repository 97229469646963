import logo from '../../../_assets/img/logo-white.png';
import stumenuicon1 from '../../../_assets/img/assessment.png';
import React from 'react';
import './CourseInterface.css';
import { Container, Row, Col, Navbar, Nav } from 'react-bootstrap';
  
function App() {
    return (
      <div className="student-course-main">
        <div className="student-dash-main">
          <Navbar  variant="dark">
            <Container fluid>
            <Navbar.Brand href="/home"><img src={logo} alt="logo" /></Navbar.Brand>
            <Nav className="ml-auto">
              <Nav.Link href="#"><img src="/user-icon.png" alt="user-icon" /> PIAZZINI LAURA</Nav.Link>
              <Nav.Link href="#"><img src="/lock-icon.png" alt="lock-icon" /> LOGOUT</Nav.Link>
            </Nav>
            </Container>
          </Navbar>
          <div className="student-dash-inner">
            <div className="student-course-row1">
              <div className="stdcrs-col-left">
                <span className="stdcrs-ttlicon"><img src={stumenuicon1} alt="nice"/></span>
                <h2>ASSESSMENT - QUESTIONNAIRE <span>Beliefs</span></h2>
              </div>
              <div className="stdcrs-col-right">
                <a href={"/student/course-questions2/"}><img src="/back-arrow.png" alt="back-arrow" /></a>
              </div>
            </div>
            <div className="student-course-row2">
              <h4>Vaccines are the main medical breakthrough ever made by man</h4>
              <div className="student-course-like student-course-rating">
                <a href="/#">https://www.salute.gov.it/portale/nuovocoronavirus/dettaglioContenutiNuovoCoronavirus.jsp</a>
                <h6>Piano vaccini anti Covid-19</h6>
                <p>Il Piano strategico nazionale dei vaccini per la prevenzione delle infezioni da SARS-CoV-2 si compone di due documenti:il documentoElementi di preparazione per la prevenzione delle infezioni da SARS-CoV-2 si compone di due documenti:il documentoElementi di preparazione</p>
                <div className="student-likebtn-row">
                  <div className="student-likebtn-col">
                    <Row className="btn-border-draw">
                      <Col></Col>
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-1" name="radio" type="radio" />
                          <label htmlFor="radio-1" className="radio-label"></label>
                        </div>
                      </Col>
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-2" name="radio" type="radio" />
                          <label htmlFor="radio-2" className="radio-label"></label>
                        </div>
                      </Col>
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-3" name="radio" type="radio" />
                          <label htmlFor="radio-3" className="radio-label"></label>
                        </div>
                      </Col>
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-4" name="radio" type="radio" />
                          <label htmlFor="radio-4" className="radio-label"></label>
                        </div>
                      </Col>
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-5" name="radio" type="radio" />
                          <label htmlFor="radio-5" className="radio-label"></label>
                        </div>
                      </Col>
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-6" name="radio" type="radio" />
                          <label htmlFor="radio-6" className="radio-label"></label>
                        </div>
                      </Col>
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-7" name="radio" type="radio" />
                          <label htmlFor="radio-7" className="radio-label"></label>
                        </div>
                      </Col>
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-8" name="radio" type="radio" />
                          <label htmlFor="radio-8" className="radio-label"></label>
                        </div>
                      </Col>
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-9" name="radio" type="radio" />
                          <label htmlFor="radio-9" className="radio-label"></label>
                        </div>
                      </Col>
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-10" name="radio" type="radio" />
                          <label htmlFor="radio-10" className="radio-label"></label>
                        </div>
                      </Col>
                      <Col></Col>
                    </Row>
                    <Row className="btn-border-text">
                      <Col></Col>
                      <Col>
                        <label>1</label>
                      </Col>
                      <Col>
                        <label>2</label>
                      </Col>
                      <Col>
                        <label>3</label>
                      </Col>
                      <Col>
                        <label>4</label>
                      </Col>
                      <Col>
                        <label>5</label>
                      </Col>
                      <Col>
                        <label>6</label>
                      </Col>
                      <Col>
                        <label>7</label>
                      </Col>
                      <Col>
                        <label>8</label>
                      </Col>
                      <Col>
                        <label>9</label>
                      </Col>
                      <Col>
                        <label>10</label>
                      </Col>
                      <Col></Col>
                    </Row>
                  </div>
                </div>
              </div>
              <div className="student-course-like student-course-rating">
                <a href="/#">https://www.salute.gov.it/portale/nuovocoronavirus/dettaglioContenutiNuovoCoronavirus.jsp</a>
                <h6>Piano vaccini anti Covid-19</h6>
                <p>Il Piano strategico nazionale dei vaccini per la prevenzione delle infezioni da SARS-CoV-2 si compone di due documenti:il documentoElementi di preparazione per la prevenzione delle infezioni da SARS-CoV-2 si compone di due documenti:il documentoElementi di preparazione</p>
                <div className="student-likebtn-row">
                  <div className="student-likebtn-col">
                    <Row className="btn-border-draw">
                      <Col></Col>
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-1" name="radio" type="radio" />
                          <label htmlFor="radio-1" className="radio-label"></label>
                        </div>
                      </Col>
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-2" name="radio" type="radio" />
                          <label htmlFor="radio-2" className="radio-label"></label>
                        </div>
                      </Col>
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-3" name="radio" type="radio" />
                          <label htmlFor="radio-3" className="radio-label"></label>
                        </div>
                      </Col>
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-4" name="radio" type="radio" />
                          <label htmlFor="radio-4" className="radio-label"></label>
                        </div>
                      </Col>
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-5" name="radio" type="radio" />
                          <label htmlFor="radio-5" className="radio-label"></label>
                        </div>
                      </Col>
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-6" name="radio" type="radio" />
                          <label htmlFor="radio-6" className="radio-label"></label>
                        </div>
                      </Col>
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-7" name="radio" type="radio" />
                          <label htmlFor="radio-7" className="radio-label"></label>
                        </div>
                      </Col>
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-8" name="radio" type="radio" />
                          <label htmlFor="radio-8" className="radio-label"></label>
                        </div>
                      </Col>
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-9" name="radio" type="radio" />
                          <label htmlFor="radio-9" className="radio-label"></label>
                        </div>
                      </Col>
                      <Col>
                        <div className="likebtn-radio">
                          <input id="radio-10" name="radio" type="radio" />
                          <label htmlFor="radio-10" className="radio-label"></label>
                        </div>
                      </Col>
                      <Col></Col>
                    </Row>
                    <Row className="btn-border-text">
                      <Col></Col>
                      <Col>
                        <label>1</label>
                      </Col>
                      <Col>
                        <label>2</label>
                      </Col>
                      <Col>
                        <label>3</label>
                      </Col>
                      <Col>
                        <label>4</label>
                      </Col>
                      <Col>
                        <label>5</label>
                      </Col>
                      <Col>
                        <label>6</label>
                      </Col>
                      <Col>
                        <label>7</label>
                      </Col>
                      <Col>
                        <label>8</label>
                      </Col>
                      <Col>
                        <label>9</label>
                      </Col>
                      <Col>
                        <label>10</label>
                      </Col>
                      <Col></Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            <div className="student-course-row3" style={{textAlign: "right"}}>
              <input type="submit" value="Save" />
            </div>
          </div>
        </div>
      </div>
    );
}
export default App;