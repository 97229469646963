import React from 'react';
import { Link } from 'react-router-dom';
import Moment from 'moment';

function SubMenuLink({ MainMenuOpeningDate, SectionName, SubMenuId, MainMenuId, ...rest }) {
    const now = Moment().format('YYYY-MM-DD');
    if(MainMenuOpeningDate && now >= MainMenuOpeningDate)
    {
        return (
            <h6 {...rest}>
                <Link to={`/student/submenu/${MainMenuId}/${SubMenuId}`} >{SectionName}</Link>
            </h6>
        );
    }
    else
    {
        return (
            <h6 {...rest}>{SectionName}</h6>
        );
    }
}

export { SubMenuLink };