import Header from '../Shared/Header';
import React, { Component } from 'react';
import { Row, Col, Accordion, Card, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import Moment from 'moment';
import { classActions } from '../../../_actions';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './ClassesPage.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from 'react-router-dom';
import { Formik, Form, Field } from "formik";
  
class ClassesExam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      todayDate: new Date(),
      datePickerIsOpen: false,
      editMode: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.openDatePicker = this.openDatePicker.bind(this)
  }

  handleChange(date) {
    if(this.props.class.items && this.props.class.items.classData.classId)
    {
      this.props.updateCalendar(this.props.class.items.classData.classId, Moment(date).format('YYYY-MM-DD'));
      this.setState({
        startDate: date
      });
    }
  }

  openDatePicker() {
    this.setState({
      datePickerIsOpen: !this.state.datePickerIsOpen,
    });
  };

  handleSubmit(values) {
    if(values)
    {
      this.props.updateCalendar(this.props.class.items.classData.classId, values);
    }
  }

  setEditMode() {
    this.setState({
      editMode: !this.state.editMode,
    });
  }

  discardForm() {
    this.props.getClasseDetail(this.props.match.params.classId);
    this.setState({
      editMode: !this.state.editMode,
    });
  }

  componentWillMount() {
    this.props.getClasseDetail(this.props.match.params.classId);
  }

  cardId(section) {
    if(section === 'Assessment') {
      return 'red-text';
    } else if(section === 'Training') {
      return 'purpal-text';
    } else if(section === 'Assessment check') {
      return 'blue-text';
    } else if(section === 'Working area') {
      return 'green-text';
    }
    return 'red-text';
  }

  formInitialValues(menus) {
    let returnData = {menu:{}};
    for (let index = 0; index < menus.menu.length; index++) {
      const menu = menus.menu[index];
      if(menu.calendar)
      {
        returnData.menu = {
          ...returnData.menu,
          [menu.sectionId]: {activated: 1, scheduledOpening: (menu.calendar) ? menu.calendar.scheduledOpening : Moment().format('YYYY-MM-DD')}
        }
        for (let i = 0; i < menu.secondLevel.length; i++) {
          const submenu = menu.secondLevel[i];
          for (let j = 0; j < submenu.thirdLevel.length; j++) {
            const thirdMenu = submenu.thirdLevel[j];
            returnData.menu = {
              ...returnData.menu,
              [thirdMenu.sectionId]: {activated: (thirdMenu.calendar && thirdMenu.calendar.activated) ? 1 : 0, scheduledOpening: null}
            }
          }
        }
      }
    }
    return returnData;
  }

  render() {
    const classDetail = this.props.class;
    const now = Moment().format('YYYY-MM-DD');
    const classId = this.props.match.params.classId;
    return (
      <div className="teacher-dash-main">
        <Header/>
        <div className="teacher-dash-inner">
          <div className="teacher-classes-main">
            {classDetail.loading && 
              <>
                <em className="text-center">Loading...</em>
              </>
            }
            {classDetail.items && 
              <>
                <Formik initialValues={this.formInitialValues(classDetail.items)} onSubmit={this.handleSubmit.bind(this)}>
                  {({
                      setFieldValue,
                      values
                  }) => (
                    <Form>
                      <Row className="tchr-exam-row1">
                        <Col xs={2} sm={2}>
                          <Link to={"/teacher/classes-page/"}><img src="/back-arrow.png" className='img-fluid' alt="back"/></Link>
                        </Col>
                        <Col xs={10} sm={8}>
                          <h1 className="pg-ttl" style={{textAlign: "center"}}>{classDetail.items.classData.name} - {classDetail.items.teacher.school.school}</h1>
                        </Col>
                      </Row>
                      <Row className="tchr-exam-row0">
                        <Col xs={12} sm={12} className="text-right">
                          {!this.state.editMode && 
                            <button className="btn btn-default" onClick={this.setEditMode.bind(this)}>Gestisci il calendario</button>
                          }
                          {this.state.editMode && 
                            <>
                              <button className="btn btn-reset d-inline-block_" style={{marginRight: '3px' }} onClick={this.discardForm.bind(this)}>Annulla</button>
                              <button className="btn btn-default d-inline-block_" type='submit'>{this.props.loading && <span className="spinner-border spinner-border-sm mr-1"></span>}Salva</button>
                            </>
                          }
                        </Col>
                      </Row>
                      <Row className="tchr-exam-row2">
                        <Accordion defaultActiveKey="0">
                          {classDetail.items.menu && classDetail.items.menu.map((menu, index) =>
                            <Card className="classes-exam-tstmain" key={index}>
                              <Card.Header id={this.cardId(menu.section)}>
                                <Accordion.Toggle as={Button} variant="link" eventKey={`${index}`}> 
                                  <span className="stdcrs-ttlicon"><img src={`/images/section_${menu.sectionId}.svg`} alt={menu.section}/></span>
                                  <h2>{menu.section} 
                                  {menu.scheduledOpening && !this.state.editMode &&
                                    <>
                                      {(now < menu.scheduledOpening) && 
                                        <>
                                          <span className="avaicls">Disponibile dal <span className="avai-datecls">{Moment(menu.scheduledOpening).format('DD/MM/YYYY')}</span></span>
                                        </>
                                      }
                                      {(now >= menu.scheduledOpening) && 
                                        <>
                                          <span className="avaicls">Disponibile</span>
                                        </>
                                      }
                                    </>
                                  }
                                  </h2> 
                                </Accordion.Toggle>
                                {this.state.editMode && 
                                  <span className="menuCalendarFields inputCalendar">
                                      <DatePicker
                                        // selected={(values.menu[menu.sectionId] && values.menu[menu.sectionId].scheduledOpening) ? new Date(Moment(values.menu[menu.sectionId].scheduledOpening).format('YYYY-MM-DD')) : (menu.scheduledOpening) ? new Date(Moment(menu.scheduledOpening).format('YYYY-MM-DD')) : this.state.startDate}
                                        selected={(values.menu[menu.sectionId] && values.menu[menu.sectionId].scheduledOpening) ? new Date(Moment(values.menu[menu.sectionId].scheduledOpening).format('YYYY-MM-DD')) : (menu.scheduledOpening) ? new Date(Moment(menu.scheduledOpening).format('YYYY-MM-DD')) : ''}
                                        dateFormat="dd/MM/yyyy"
                                        onChange={(date) => {
                                          setFieldValue(`menu.${menu.sectionId}.scheduledOpening`, Moment(date).format('YYYY-MM-DD'));
                                        }}
                                        placeholderText="Disponibile dal..."
                                        className="changeCalendar form-control"
                                        shouldCloseOnSelect={true}
                                        name={`menu.${menu.sectionId}.scheduledOpening`}
                                      />
                                  </span>
                                }
                              </Card.Header>
                              <Accordion.Collapse eventKey={`${index}`}>
                                <ul className="exmcls-mainmenu">
                                  {menu.secondLevel && menu.secondLevel.map((secondMenu, ind) =>
                                    <li key={ind}><Link to="#">{secondMenu.section}</Link>
                                      <ul className="exmcls-submenu">
                                        {secondMenu && secondMenu.thirdLevel.map((thirdMenu, i) =>
                                          <li key={i}>
                                            <label className="control control--checkbox">
                                              <Field
                                                name={`menu.${thirdMenu.sectionId}.activated`}
                                                disabled={(!this.state.editMode) ? true : false}
                                                value={`1`}
                                              >{({ field, form }) => {
                                                return (
                                                  <>
                                                  {field.value === 1 &&
                                                    <input type="checkbox" checked disabled={(!this.state.editMode) ? true : false} {...field} />
                                                  }
                                                  {field.value !== 1 &&
                                                    <input type="checkbox" disabled={(!this.state.editMode) ? true : false} {...field} />
                                                  }
                                                  </>
                                                );
                                              }}</Field>
                                              <div className="control__indicator"></div>
                                                  <Link to={`/teacher/classes-state/${classId}/${thirdMenu.sectionId}`}>{thirdMenu.section} <span>({thirdMenu.noOfStudentAttempt}/{classDetail.items.classTot})</span></Link>
                                              </label>
                                          </li>
                                        )}
                                      </ul>
                                    </li>
                                  )}
                                </ul>
                              </Accordion.Collapse>
                            </Card>
                          )}
                        </Accordion>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </>
            }
          </div>
        </div>        
      </div>
    );
  }
}
const mapStateToProps = state => ({
  class: state.classDetail,
  user: state.userAuth.user,
  loading: state.updateCalendar.loading
})

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    getClasseDetail: (classId) => dispatch(classActions.getClasseDetail(classId)),
    updateCalendar: (classId, scheduledOpening) => dispatch(classActions.updateCalendar(classId, scheduledOpening))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ClassesExam)