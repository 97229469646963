import React, { Component } from 'react';
import { Formik, Form } from "formik";
import Moment from 'moment';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { CourceMainContent, SectionConvergence, WorkSheet } from '../../../_components'
import { Link } from 'react-router-dom';
import Header from '../Shared/Header';
import { classActions, showAlert } from '../../../_actions';
import './ClassesPage.css';
  
class ClassesExamCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      multiTextShowItem: 0,
    };
  }

  componentWillMount() {
    this.props.getSectionDataById(this.props.match.params.sectionId, this.props.match.params.studentId);
  }

  handleSubmit(values) {
    if(values)
    {
      const onConfirm = () => {this.props.postFeedback(values);};
      this.props.showAlert({
          type: 'warning',
          title: 'Are you sure?',
          content: 'You can\'t modify feedback after confirm.',
          showCancel: true,
          confirmBtnText: 'Confirm',
          cancelBtnText: 'Cancel',
          cancelBtnCssClass: 'btn btn-danger',
          cancelBtnBsStyle: 'danger',
          confirmBtnBsStyle: 'success',
          onConfirm: onConfirm
      });
    }
  }

  render() {
    const section = this.props.section;
    const user = this.props.user;
    const sectionId = this.props.match.params.sectionId;
    const studentId = this.props.match.params.studentId;
    const classId = this.props.match.params.classId;
    const loading = this.props.feedback.loading;

    const showFeedback = () => {
      if(section.items && section.items.childHeader.studentSections && section.items.childHeader.studentSections[0])
      {
        let isVisable = false;
        if(section.items.childHeader.studentSections[0].state === 3 && user && user.role.role && user.role.role === "Teacher")
        {
          if(section.items.childHeader.structure[0].worksheet)
          {
            if(section.items.childHeader.structure[0].worksheet.prospective)
            {
              if(section.items.childHeader.structure[0].worksheet.prospective.expected_feedback === 1 || section.items.childHeader.expected_feedback === 1)
              {
                isVisable = true;
              }
            }
            if(section.items.childHeader.structure[0].worksheet.in_favour)
            {
              for (let index = 0; index < section.items.childHeader.structure[0].worksheet.in_favour.length; index++) {
                const item = section.items.childHeader.structure[0].worksheet.in_favour[index];
                if(item.expected_feedback === 1 || section.items.childHeader.expected_feedback === 1)
                {
                  isVisable = true;
                  break;
                }
              }
            }
            if(section.items.childHeader.structure[0].worksheet.opposite)
            {
              for (let index = 0; index < section.items.childHeader.structure[0].worksheet.opposite.length; index++) {
                const item = section.items.childHeader.structure[0].worksheet.opposite[index];
                if(item.expected_feedback === 1 || section.items.childHeader.expected_feedback === 1)
                {
                  isVisable = true;
                  break;
                }
              }
            }
          }
          else
          {
            if(section.items.childHeader.structure[0])
            {
              for (let index = 0; index < section.items.childHeader.structure[0].items.length; index++) {
                const item = section.items.childHeader.structure[0].items[index];
                if(item.expected_feedback === 1 || section.items.childHeader.expected_feedback === 1)
                {
                  isVisable = true;
                  break;
                }
              }
            }
            if(section.items.childHeader.structure[1])
            {
              for (let index = 0; index < section.items.childHeader.structure[1].items.length; index++) {
                const item = section.items.childHeader.structure[1].items[index];
                if(item.expected_feedback === 1 || section.items.childHeader.expected_feedback === 1)
                {
                  isVisable = true;
                  break;
                }
              }
            }
          }
          if(section.items.childHeader.expected_feedback === 1)
          {
            isVisable = true;
          }
        }
        return isVisable;
      }
      else
      {
        return true;
      }
    }

    const showConvergence = () => {
      return true;
    }

    return (
      <>
      <div className="student-course-main">
        <div className="student-dash-main">          
          <Header />
          <div className="student-dash-inner">
            {section.items &&
              <Row className="clss-exmchk-ttl">
                <Col md={2} xs={2} sm={2}><Link to={`/teacher/classes-state/${classId}/${sectionId}`}><img src="/back-arrow.png" className='img-fluid' alt="back"/></Link></Col>
                <Col md={8} xs={10} sm={10}><h2 style={{textAlign: "center"}}>{section.items.studentData.name} - {section.items.studentData.code}
                {section.items.childHeader.studentSections && section.items.childHeader.studentSections[0] && 
                  <span>{Moment(section.items.childHeader.studentSections[0].start).format('DD/MM/YYYY')}</span>
                }
                </h2></Col>
                <Col md={2} xs={12} sm={12}></Col>
              </Row>
            }
            {section.loading && 
              <>
                <em className="text-center">Loading...</em>
              </>
            }
            {section.items &&
              <>
              <Formik initialValues={{ answers : {}, logs : {
                startTime: Moment().format('YYYY-MM-DD hh:mm:ss'),
                job:[],
                endTime:''
              }, sectionId: section.items.childHeader.sectionId, studentId: studentId, classId: classId }} onSubmit={this.handleSubmit.bind(this)}>
                {({
                    setFieldValue,
                    values
                }) => (
                  <Form>
                    <div className={`student-course-row1 ${section.items.parentHeader.name.toLowerCase().replace(' ', '_')}`}>
                      <div className="stdcrs-col-left">
                        <span className="stdcrs-ttlicon"><img src={`/images/${section.items.parentHeader.name}.svg`} alt={(section.items.parentHeader.name) ? section.items.parentHeader.name : ''}/></span>
                        <h2>{(section.items.parentHeader.name) ? section.items.parentHeader.name : ''} - {(section.items.subParentHeader.name) ? section.items.subParentHeader.name : ''} <span>{(section.items.childHeader.name) ? section.items.childHeader.name : ''}</span></h2>
                      </div>
                      <div className="stdcrs-col-right"></div>
                    </div>
                    <div className={`student-course-row2 ${section.items.parentHeader.name.toLowerCase().replace(' ', '_')}`}>
                      {section.items.childHeader.structure && section.items.childHeader.structure[0] && section.items.childHeader.structure.length === 2 && 
                        <div className="stdcrs-left-cont">
                          <div className="stdcrslft-cont-inner">
                            {section.items.childHeader.structure[0].title &&
                              <h5>{section.items.childHeader.structure[0].title}</h5>
                            }
                            
                            {section.items.childHeader.structure[0].items.map((item, index) =>
                              <div key={`text_${index}`}>
                                <CourceMainContent item={item} index={index} setFieldValue={setFieldValue} showQuestion={true} isEditableForm={false} studentSections={section.items.childHeader.studentSections} showConvergence={true} showDisableConvergence={true} showFeedback={showFeedback()} values={values}/>
                              </div>
                            )}
                          </div>
                        </div>
                      }
                      {section.items.childHeader.structure && section.items.childHeader.structure[1] && section.items.childHeader.structure.length === 2 &&
                        <div className="stdcrs-right-ques">
                          <div className="stdcrsrgt-ques-inner">
                            {section.items.childHeader.structure[1].title &&
                              <h4>{section.items.childHeader.structure[1].title}</h4>
                            }
                            
                            {section.items.childHeader.structure[1].items.map((item, index) => 
                              <div key={`${item.itemId}_${index}`} >
                                <CourceMainContent item={item} index={index} setFieldValue={setFieldValue} showQuestion={true} isEditableForm={false} studentSections={section.items.childHeader.studentSections} showConvergence={true} showDisableConvergence={true} showFeedback={showFeedback()} values={values}/>
                              </div>
                            )}
                          </div>
                        </div>
                      }
                      {section.items.childHeader.structure && section.items.childHeader.structure[0] && section.items.childHeader.structure.length === 1 && 
                        <>
                          {section.items.childHeader.structure[0].title &&
                            <h5 className="mb-4">{section.items.childHeader.structure[0].title}</h5>
                          }
                          {!section.items.childHeader.structure[0].worksheet && section.items.childHeader.structure[0].multipleTexts !== true && 
                            <>
                              {section.items.childHeader.structure[0].items.map((item, index) =>
                                <div key={`${item.itemId}_${index}`} >
                                  <CourceMainContent item={item} index={index} setFieldValue={setFieldValue} showQuestion={true} isEditableForm={false} studentSections={section.items.childHeader.studentSections} valuesshowConvergence={true} showFeedback={showFeedback()} showDisableConvergence={true} values={values}/>
                                </div>
                              )}
                            </>
                          }
                          {section.items.childHeader.structure[0].worksheet && 
                            <>
                              <h4>{section.items.childHeader.structure[0].title}</h4>
                              <WorkSheet worksheet={section.items.childHeader.structure[0].worksheet} isEditableForm={false} values={values} studentSections={section.items.childHeader.studentSections} setFieldValue={setFieldValue} buttons={section.items.childHeader.structure[0].buttons} loading={loading} showConvergence={showConvergence} showDisableConvergence={showConvergence} showFeedback={showFeedback()}/>
                            </>
                          }
                          {!section.items.childHeader.structure[0].worksheet && section.items.childHeader.structure[0].multipleTexts === true && 
                            <>
                              <div className="stdcrs-left-cont">
                                <div className="stdcrslft-cont-inner">
                                  <ul>
                                    {section.items.childHeader.structure[0].items.map((item, index) =>
                                      {
                                        return(
                                          <li key={`link_${index}`} className={`${((this.state.multiTextShowItem === item.itemId) || (this.state.multiTextShowItem === 0 && index === 0)) ? "acive" : ""}`} style={{cursor:'pointer'}} onClick={() => {this.setState({multiTextShowItem: item.itemId});}}>{item.title}</li>
                                        )
                                      }
                                    )}
                                  </ul>
                                </div> 
                              </div>
                              <div className="stdcrs-right-ques">
                                <div className="stdcrsrgt-ques-inner">
                                  {section.items.childHeader.structure[0].items.map((item, index) =>
                                    <div key={`${item.itemId}_${index}`} >
                                      <CourceMainContent item={item} index={index} setFieldValue={setFieldValue} showQuestion={((this.state.multiTextShowItem === item.itemId) || (this.state.multiTextShowItem === 0 && index === 0))} isEditableForm={false} studentSections={section.items.childHeader.studentSections} showConvergence={true} showDisableConvergence={true} showFeedback={showFeedback()} values={values}/>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </>
                          }
                        </>
                      }
                    </div>
                    {(section.items.childHeader.expected_convergence === 1 || section.items.childHeader.expected_feedback === 1) &&
                      <SectionConvergence section={section.items.childHeader} showDisableConvergence={true} studentSections={section.items.childHeader.studentSections} loading={loading} showFeedback={showFeedback()}/>
                    }
                    {(showFeedback()) && 
                      <div className="student-course-row3 ssa-main pt-0">
                        <div style={{textAlign: "right"}}>
                          <button type="submit" disabled={(loading) ? true : false}>{loading && <span className="spinner-border spinner-border-sm mr-1"></span>}INVIA</button>
                        </div>
                      </div>
                    }
                    </Form>
                  )}
                </Formik>
              </>
            }
          </div>
        </div>
      </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  section: state.sectionData,
  feedback: state.feedback,
  user: state.userAuth.user
})

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    getSectionDataById: (sectionId, studentId) => dispatch(classActions.getSectionDataById(sectionId, studentId)),
    postFeedback: (answers) => dispatch(classActions.postFeedback(answers)),
    showAlert: (data) => dispatch(showAlert(data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ClassesExamCheck)
