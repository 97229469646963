import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function PrivateRoute({ component: Component, roles, ...rest }) {
    return (
        <Route {...rest} render={props => {
            if(roles.includes('Student'))
            {
                if (!localStorage.getItem('student')) {
                    // not logged in so redirect to login page with the return url
                    return <Redirect to={{ pathname: '/student/login', state: { from: props.location } }} />
                }
            }
            if(roles.includes('Teacher') || roles.includes('Researcher'))
            {
                if (localStorage.getItem('user')) {
                    const user = JSON.parse(localStorage.getItem('user'));
                    if(!roles.includes(user.role.role))
                    {
                        if(roles.includes('Teacher'))
                        {
                            // not logged in so redirect to login page with the return url
                            return <Redirect to={{ pathname: '/teacher/login', state: { from: props.location } }} />
                        }
                        else
                        {
                            // not logged in so redirect to login page with the return url
                            return <Redirect to={{ pathname: '/researcher/login', state: { from: props.location } }} />
                        }
                    }
                }
                else
                {
                    if(roles.includes('Teacher'))
                    {
                        // not logged in so redirect to login page with the return url
                        return <Redirect to={{ pathname: '/teacher/login', state: { from: props.location } }} />
                    }
                    else
                    {
                        // not logged in so redirect to login page with the return url
                        return <Redirect to={{ pathname: '/researcher/login', state: { from: props.location } }} />
                    }
                }
            }

            // logged in so return component
            return <Component {...props} />
        }} />
    );
}

export { PrivateRoute };