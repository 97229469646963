import { userConstants } from '../_constants';
import { userService } from '../_services';
import { showAlert } from '.';
import { history } from '../_helpers';

export const userActions = {
    login,
    logout
};

function login(email, password, role, from) {
    return dispatch => {
        dispatch(request({ email, password, role }));

        userService.login(email, password, role)
            .then(
                user => { 
                    dispatch(success(user));
                    history.push(from);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(showAlert({
                        type: 'danger',
                        title: 'Oops!',
                        content: error.toString(),
                        showCancel: false,
                    }));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}
