import logo from '../../../_assets/img/logo-white.png';
import stumenuicon1 from '../../../_assets/img/assessment.png';
import React from 'react';
import './CourseInterface.css';
import { Container, Navbar, Nav } from 'react-bootstrap';
  
function App() {
    return (
      <div className="student-course-main">
        <div className="student-dash-main">
          <Navbar  variant="dark">
            <Container fluid>
            <Navbar.Brand href="/home"><img src={logo} alt="logo" /></Navbar.Brand>
            <Nav className="ml-auto">
              <Nav.Link href="#"><img src="/user-icon.png" alt="user-icon" /> PIAZZINI LAURA</Nav.Link>
              <Nav.Link href="#"><img src="/lock-icon.png" alt="lock-icon" /> LOGOUT</Nav.Link>
            </Nav>
            </Container>
          </Navbar>
          <div className="student-dash-inner">
            <div className="student-course-row1">
              <div className="stdcrs-col-left">
                <span className="stdcrs-ttlicon"><img src={stumenuicon1} alt="nice"/></span>
                <h2>ASSESSMENT - QUESTIONNAIRE <span>Beliefs</span></h2>
              </div>
              <div className="stdcrs-col-right">
                <a href={"/student/course-questions2/"}><img src="/back-arrow.png" alt="back-arrow" /></a>
              </div>
            </div>
            <div className="student-course-row2">
              <div className="stdtcrs-worksheet">
                <div className="stdtcrs-worksheet-row1">
                  <textarea className="form-control"></textarea>
                </div>
                <div className="stdtcrs-worksheet-row2">
                    <div className="stdtcrs-wrkstrw2-hdr">
                      <div className="stdtcrs-wrkstrw2-hdr-left">
                        <h6>IN FAVOUR</h6>
                      </div>
                      <div className="stdtcrs-wrkstrw2-hdr-right">
                        <h6>OPPOSITE</h6>
                      </div>
                    </div>
                    <div className="stdtcrs-wrkstrw2-cont">
                      <div className="stdtcrs-wrkstrw2-cont-left">
                        <label>Arguments</label>
                        <textarea className="form-control"></textarea>
                      </div>
                      <div className="stdtcrs-wrkstrw2-cont-right">
                        <label>Arguments</label>
                        <textarea className="form-control"></textarea>
                      </div>
                    </div>
                    <div className="stdtcrs-wrkstrw2-cont">
                      <div className="stdtcrs-wrkstrw2-cont-left">
                        <label>Evidences</label>
                        <textarea className="form-control"></textarea>
                      </div>
                      <div className="stdtcrs-wrkstrw2-cont-right">
                        <label>Evidences</label>
                        <textarea className="form-control"></textarea>
                      </div>
                    </div>
                    <div className="stdtcrs-wrkstrw2-cont">
                      <div className="stdtcrs-wrkstrw2-cont-left">
                        <label>Sources</label>
                        <textarea className="form-control"></textarea>
                      </div>
                      <div className="stdtcrs-wrkstrw2-cont-right">
                        <label>Sources</label>
                        <textarea className="form-control"></textarea>
                      </div>
                    </div>
                </div>
              </div>
            </div>
            <div className="student-course-row3" style={{textAlign: "right"}}>
              <input type="submit" value="Save" />
            </div>
          </div>
        </div>
      </div>
    );
}
export default App;