import { studentConstants } from '../_constants';
import { studentService } from '../_services';
import { showAlert } from '.';
import { history } from '../_helpers';

export const studentActions = {
    login,
    logout
};

function login(code, from) {
    return dispatch => {
        dispatch(request({ code }));

        studentService.login(code)
            .then(
                user => { 
                    dispatch(success(user));
                    history.push(from);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(showAlert({
                        type: 'danger',
                        title: 'Oops!',
                        content: error.toString(),
                        showCancel: false,
                    }));
                }
            );
    };

    function request(user) { return { type: studentConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: studentConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: studentConstants.LOGIN_FAILURE, error } }
}

function logout() {
    studentService.logout();
    return { type: studentConstants.LOGOUT };
}
