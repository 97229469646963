import { classConstants } from '../_constants';

export function classes(state = {}, action) {
    switch (action.type) {
        case classConstants.CLASS_REQUEST:
            return {
                loading: true
            };
        case classConstants.CLASS_SUCCESS:
            return {
                items: action.classes
            };
        case classConstants.CLASS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}

export function classDetail(state = {}, action) {
    switch (action.type) {
        case classConstants.CLASS_DETAIL_REQUEST:
            return {
                loading: true
            };
        case classConstants.CLASS_DETAIL_SUCCESS:
            return {
                items: action.classes
            };
        case classConstants.CLASS_DETAIL_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}

export function updateCalendar(state = {}, action) {
    switch (action.type) {
        case classConstants.UPDATE_CALENDAR_REQUEST:
            return {
                loading: true
            };
        case classConstants.CLASS_DETAIL_SUCCESS:
            return {
                items: action.calendar
            };
        case classConstants.UPDATE_CALENDAR_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}

export function students(state = {}, action) {
    switch (action.type) {
        case classConstants.SECTION_STUDENTS_REQUEST:
            return {
                loading: true
            };
        case classConstants.SECTION_STUDENTS_SUCCESS:
            return {
                items: action.students
            };
        case classConstants.SECTION_STUDENTS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}

export function sectionHeader(state = {}, action) {
    switch (action.type) {
        case classConstants.SECTION_HEADER_REQUEST:
            return {
                loading: true
            };
        case classConstants.SECTION_HEADER_SUCCESS:
            return {
                items: action.sectionHeader
            };
        case classConstants.SECTION_HEADER_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}

export function classHeader(state = {}, action) {
    switch (action.type) {
        case classConstants.CLASS_HEADER_REQUEST:
            return {
                loading: true
            };
        case classConstants.CLASS_HEADER_SUCCESS:
            return {
                items: action.classHeader
            };
        case classConstants.CLASS_HEADER_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}

export function sectionData(state = {}, action) {
    switch (action.type) {
        case classConstants.SECTION_DATA_REQUEST:
            return {
                loading: true
            };
        case classConstants.SECTION_DATA_SUCCESS:
            return {
                items: action.sectionData
            };
        case classConstants.SECTION_DATA_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}

export function feedback(state = {}, action) {
    switch (action.type) {
        case classConstants.FEEDBACK_REQUEST:
            return {
                loading: true
            };
        case classConstants.FEEDBACK_SUCCESS:
            return {
                items: action.answers
            };
        case classConstants.FEEDBACK_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}