import { authHeader } from '../_helpers';
import { studentService } from './';
const API_URL = process.env.REACT_APP_API_URL;

export const courseService = { 
    getSectionDataById,
    postAnswers,
    postConvergence
}

function getSectionDataById(sectionId, studentId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${API_URL}/students/get-student-course-header?sectionId=${sectionId}&studentId=${studentId}`, requestOptions).then(handleResponse);
}

function postAnswers(answers) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(answers)
    };

    return fetch(`${API_URL}/students/post-answers`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}

function postConvergence(answers) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(answers)
    };

    return fetch(`${API_URL}/students/post-convergence`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                studentService.logout();
                window.location.reload();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}