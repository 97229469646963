import React, { useState, useEffect } from 'react';
import './CourseInterface.css';
import Header from '../Shared/Header';
import { Link } from 'react-router-dom';
import { courseActions, showAlert } from '../../../_actions';
import { CourceMainContent, SectionConvergence, WorkSheet } from '../../../_components'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from "formik";
import Moment from 'moment';

function CourseInterface(props) {
    const section = useSelector(state => state.section);
    const { sectionId } = props.match.params;
    const user = useSelector(state => state.authentication.user);
    const dispatch = useDispatch();
    const [showQuestion, setShowQuestion] = useState(false);
    const [showLeftQuestion, setShowLeftQuestion] = useState(true);
    const [multiTextShowItem, setMultiTextShowItem] = useState(0);
    const loading = useSelector(state => state.answer.loading);

    useEffect(() => {
      dispatch(courseActions.getSectionDataById(sectionId, user.studentId));
    }, [sectionId, dispatch, user.studentId]);

    function handleDisplayQuestions(e, type, setFieldValue, values) {
      e.preventDefault();
      setShowQuestion(!showQuestion);
      setFieldValue('logs.job.'+values.logs.job.length, {button: type, time: Moment().format('YYYY-MM-DD hh:mm:ss')});
    }

    function handleDisplayQuestionsAndHideCol(e, type, setFieldValue, values) {
      e.preventDefault();
      setShowQuestion(!showQuestion);
      setShowLeftQuestion(false);
      setFieldValue('logs.job.'+values.logs.job.length, {button: type, time: Moment().format('YYYY-MM-DD hh:mm:ss')});
    }

    function handleSubmit(values) {
      values.logs.endTime = Moment().format('YYYY-MM-DD hh:mm:ss');
      if(values && isEditableForm())
      {
        const onConfirm = () => {dispatch(courseActions.postAnswers(values));};
        dispatch(showAlert({
            type: 'warning',
            title: 'Sei sicuro?',
            content: 'Confermi il temine di questa sezione?',
            showCancel: true,
            confirmBtnText: 'Conferma',
            cancelBtnText: 'Annulla',
            cancelBtnCssClass: 'btn btn-reset',
            cancelBtnBsStyle: 'reset',
            confirmBtnBsStyle: 'default',
            onConfirm: onConfirm
        }));
      }
      if(values && showConvergence())
      {
        const onConfirm = () => {dispatch(courseActions.postConvergence(values));};
        dispatch(showAlert({
            type: 'warning',
            title: 'Sei sicuro?',
            content: 'Confermi il temine di questa sezione?',
            showCancel: true,
            confirmBtnText: 'Conferma',
            cancelBtnText: 'Annulla',
            cancelBtnCssClass: 'btn btn-reset',
            cancelBtnBsStyle: 'reset',
            confirmBtnBsStyle: 'default',
            onConfirm: onConfirm
        }));
      }
    }

    const isEditableForm = () => {
      if(section.items && section.items.childHeader.studentSections && section.items.childHeader.studentSections[0])
      {
        if(section.items.childHeader.studentSections[0].state === 1)
        {
          return true;
        }
        else
        {
          return false;
        }
      }
      else
      {
        return true;
      }
    }

    const showConvergence = (isSubmitBtn) => {
      if(!isSubmitBtn)
      {
        isSubmitBtn = false;
      }
      if(section.items && section.items.childHeader.studentSections && section.items.childHeader.studentSections[0])
      {
        let isVisable = false;
        if(section.items.childHeader.studentSections[0].state === 2)
        {
          if(section.items.childHeader.structure[0].worksheet)
          {
            if(section.items.childHeader.structure[0].worksheet.prospective)
            {
              if(isSubmitBtn)
              {
                if(section.items.childHeader.structure[0].worksheet.prospective.expected_convergence === 1 && section.items.childHeader.expected_convergence !== 1)
                {
                  isVisable = true;
                }
              }
              else
              {
                if(section.items.childHeader.structure[0].worksheet.prospective.expected_convergence === 1 || section.items.childHeader.expected_convergence === 1)
                {
                  isVisable = true;
                }
              }
            }
            if(section.items.childHeader.structure[0].worksheet.in_favour)
            {
              for (let index = 0; index < section.items.childHeader.structure[0].worksheet.in_favour.length; index++) {
                const item = section.items.childHeader.structure[0].worksheet.in_favour[index];
                if(isSubmitBtn)
                {
                  if(item.expected_convergence === 1 && section.items.childHeader.expected_convergence !== 1)
                  {
                    isVisable = true;
                    break;
                  }
                }
                else
                {
                  if(item.expected_convergence === 1 || section.items.childHeader.expected_convergence === 1)
                  {
                    isVisable = true;
                    break;
                  }
                }
              }
            }
            if(section.items.childHeader.structure[0].worksheet.opposite)
            {
              for (let index = 0; index < section.items.childHeader.structure[0].worksheet.opposite.length; index++) {
                const item = section.items.childHeader.structure[0].worksheet.opposite[index];
                if(isSubmitBtn)
                {
                  if(item.expected_convergence === 1 && section.items.childHeader.expected_convergence !== 1)
                  {
                    isVisable = true;
                    break;
                  }
                }
                else
                {
                  if(item.expected_convergence === 1 || section.items.childHeader.expected_convergence === 1)
                  {
                    isVisable = true;
                    break;
                  }
                }
              }
            }
          }
          else
          {
            if(section.items.childHeader.structure[0])
            {
              for (let index = 0; index < section.items.childHeader.structure[0].items.length; index++) {
                const item = section.items.childHeader.structure[0].items[index];
                if(isSubmitBtn)
                {
                  if(item.expected_convergence === 1 && section.items.childHeader.expected_convergence !== 1)
                  {
                    isVisable = true;
                    break;
                  }
                }
                else
                {
                  if(item.expected_convergence === 1 || section.items.childHeader.expected_convergence === 1)
                  {
                    isVisable = true;
                    break;
                  }
                }
              }
            }
            if(section.items.childHeader.structure[1])
            {
              for (let index = 0; index < section.items.childHeader.structure[1].items.length; index++) {
                const item = section.items.childHeader.structure[1].items[index];
                if(isSubmitBtn)
                {
                  if(item.expected_convergence === 1 && section.items.childHeader.expected_convergence !== 1)
                  {
                    isVisable = true;
                    break;
                  }
                }
                else
                {
                  if(item.expected_convergence === 1 || section.items.childHeader.expected_convergence === 1)
                  {
                    isVisable = true;
                    break;
                  }
                }
              }
            }
          }
        }
        return isVisable;
      }
      else
      {
        return true;
      }
    }

    const showDisableConvergence = () => {
      if(section.items && section.items.childHeader.studentSections && section.items.childHeader.studentSections[0])
      {
        if(section.items.childHeader.studentSections[0].state !== 2 && section.items.childHeader.studentSections[0].state !== 1)
        {
          return true;
        }
        else
        {
          return false;
        }
      }
      else
      {
        return true;
      }
    }

    return (
      <div className="student-course-main">
        <div className="student-dash-main">
          <Header/>
          <div className="student-dash-inner">
            {section.loading && 
              <>
                <em className="text-center">Loading...</em>
              </>
            }
            {section.items &&
              <>
              <Formik initialValues={{ answers : {}, logs : {
                startTime: Moment().format('YYYY-MM-DD hh:mm:ss'),
                job:[],
                endTime:''
              }, sectionId: section.items.childHeader.sectionId, studentId: user.studentId, test_answers: 0 }} onSubmit={handleSubmit}>
                {({
                    setFieldValue,
                    values
                }) => (
                  <Form>
                    <div className={`student-course-row1 ${section.items.parentHeader.name.toLowerCase().replace(' ', '_')}`}>
                      <div className="stdcrs-col-right">
                        <Link to={`/student/submenu/${section.items.parentHeader.sectionId}/${section.items.subParentHeader.sectionId}`}><img src="/back-arrow.png" alt="back" /></Link>
                      </div>
                      <div className="stdcrs-col-left">
                        <div><span className="stdcrs-ttlicon"><img src={`/images/section_${section.items.parentHeader.sectionId}.svg`} alt={(section.items.parentHeader.name) ? section.items.parentHeader.name : ''}/></span></div>
                        <h2>{(section.items.parentHeader.name) ? section.items.parentHeader.name : ''} - {(section.items.subParentHeader.name) ? section.items.subParentHeader.name : ''}</h2>
                        <h3><span>{(section.items.childHeader.name) ? section.items.childHeader.name : ''}</span></h3>
                      </div>
                    </div>
                    <div className={`student-course-row2 ${section.items.parentHeader.name.toLowerCase().replace(' ', '_')}`}>
                      {section.items.childHeader.structure && section.items.childHeader.structure[0] && section.items.childHeader.structure.length === 2 && 
                        <div className={`stdcrs-left-cont ${(!(section.items.childHeader.structure[1].defaultHidden !== true || showQuestion || section.items.childHeader.studentSections[0].state !== 1)) ? 'full' : ''} ${(!showLeftQuestion) ? 'd-none' : ''}`}>
                          <div className="stdcrslft-cont-inner">
                            {section.items.childHeader.structure[0].title &&
                              <h6>{section.items.childHeader.structure[0].title}</h6>
                            }
                           {section.items.childHeader.structure[0].multipleTexts == true ? (
                             <>
                              <div className="stdcrs-left-cont">
                                <div className="stdcrslft-cont-inner">
                                  <ul>
                                    {section.items.childHeader.structure[0].items.map((item, index) =>
                                      {
                                        if(multiTextShowItem === 0)
                                        {
                                          setMultiTextShowItem(item.itemId);
                                        }
                                        return(
                                          <li key={`link_${index}`} className={`${(multiTextShowItem === item.itemId) ? "acive" : ""}`} style={{cursor:'pointer'}} onClick={() => setMultiTextShowItem(item.itemId)}>{item.title}</li>
                                        )
                                      }
                                    )}
                                  </ul>
                                </div> 
                              </div>
                              <div className="stdcrs-right-ques">
                                <div className="stdcrsrgt-ques-inner">
                                  {section.items.childHeader.structure[0].items.map((item, index) =>
                                    <div key={`${item.itemId}_${index}`} >
                                      <CourceMainContent item={item} index={index} setFieldValue={setFieldValue} showQuestion={((section.items.childHeader.structure[0].defaultHidden !== true || showQuestion || section.items.childHeader.studentSections[0].state !== 1) && multiTextShowItem === item.itemId)} isEditableForm={isEditableForm()} studentSections={section.items.childHeader.studentSections} showConvergence={showConvergence()} showDisableConvergence={showDisableConvergence()} values={values} section={section.items.childHeader}/>
                                    </div>
                                  )}
                                </div>
                              </div>
                              </>
                           ): (
                             <>
                            {section.items.childHeader.structure[0].items.map((item, index) =>
                              <div key={`text_${index}`}> 
                                <CourceMainContent item={item} index={index} setFieldValue={setFieldValue} showQuestion={(section.items.childHeader.structure[0].defaultHidden !== true || showQuestion || section.items.childHeader.studentSections[0].state !== 1)} isEditableForm={isEditableForm()} studentSections={section.items.childHeader.studentSections} showConvergence={showConvergence()} showDisableConvergence={showDisableConvergence()} values={values} section={section.items.childHeader}/>
                              </div>
                            )}
                            </>
                           )} 
                          </div>
                          {section.items.childHeader.structure[0].buttons && 
                            <>
                              {section.items.childHeader.structure[0].buttons.map((button, index) =>
                                <div key={`btn_${index}`}>
                                  {!showQuestion && section.items.childHeader.studentSections[0].state === 1 && button.type === 'buttonShow' && 
                                    <div className="stdcrslft-btn">
                                      <a href="/#" onClick={(e) => handleDisplayQuestions(e, button.type, setFieldValue, values)}>{button.label}</a>
                                    </div>
                                  }
                                  {section.items.childHeader.studentSections[0].state === 1 && button.type === 'buttonShowAndHide' && 
                                    <div className="stdcrslft-btn">
                                      <a href="/#" onClick={(e) => handleDisplayQuestionsAndHideCol(e, button.type, setFieldValue, values)}>{button.label}</a>
                                    </div>
                                  }
                                </div>
                              )}
                            </>
                          }
                        </div>
                      }
                      {section.items.childHeader.structure && section.items.childHeader.structure[1] && section.items.childHeader.structure.length === 2 &&
                        <div className={`stdcrs-right-ques ${(!(section.items.childHeader.structure[1].defaultHidden !== true || showQuestion || section.items.childHeader.studentSections[0].state !== 1)) ? 'd-none' : ''} ${(!showLeftQuestion) ? 'full' : ''}`}>
                          <div className="stdcrsrgt-ques-inner">
                            {(section.items.childHeader.structure[1].defaultHidden !== true || showQuestion || section.items.childHeader.studentSections[0].state !== 1) &&
                              <>
                              <h6>{section.items.childHeader.structure[1].title}</h6>
                              {(section.items.childHeader.structure[1].worksheet) ? (
                                <>
                                <WorkSheet worksheet={section.items.childHeader.structure[1].worksheet} isEditableForm={isEditableForm()} values={values} studentSections={section.items.childHeader.studentSections} setFieldValue={setFieldValue} buttons={section.items.childHeader.structure[1].buttons} loading={loading} showConvergence={showConvergence} showDisableConvergence={showDisableConvergence()}/>
                                </>
                              ) : (
                                <>
                                {section.items.childHeader.structure[1].items.map((item, index) => 
                                  <div key={`${item.itemId}_${index}`} >  
                                    <CourceMainContent item={item} index={index} setFieldValue={setFieldValue} showQuestion={(section.items.childHeader.structure[1].defaultHidden !== true || showQuestion || section.items.childHeader.studentSections[0].state !== 1)} isEditableForm={isEditableForm()} studentSections={section.items.childHeader.studentSections} showConvergence={showConvergence()} showDisableConvergence={showDisableConvergence()} values={values} section={section.items.childHeader}/>
                                  </div>
                                )}
                                </>
                              )}
                              </>
                            }
                          </div>
                          {section.items.childHeader.structure[1].buttons && 
                            <>
                              {section.items.childHeader.structure[1].buttons.map((button, index) =>
                                <div key={`btn_${index}`}>
                                  {(isEditableForm() || showConvergence(true)) && (section.items.childHeader.structure[1].defaultHidden !== true || showQuestion || section.items.childHeader.studentSections[0].state !== 1) && (button.type === 'buttonConfirm' || button.type === 'buttonTest') && 
                                    <div className="stdcrslft-btn">
                                      {(button.type === 'buttonTest') && 
                                        <button type="submit" disabled={(loading) ? true : false} className="submitLink" onClick={() => setFieldValue(`test_answers`, 1)}>{loading && <span className="spinner-border spinner-border-sm mr-1"></span>}{button.label}</button>
                                      }
                                      {(button.type === 'buttonConfirm') && 
                                        <button type="submit" disabled={(loading) ? true : false} className="submitLink">{loading && <span className="spinner-border spinner-border-sm mr-1"></span>}{button.label}</button>
                                      }
                                    </div>
                                  }
                                </div>
                              )}
                            </>
                          }
                        </div>
                      }
                      {section.items.childHeader.structure && section.items.childHeader.structure[0] && section.items.childHeader.structure.length === 1 && 
                        <>
                          {section.items.childHeader.structure[0].title &&
                            <h6 className="mb-4">{section.items.childHeader.structure[0].title}</h6>
                          }
                          {!section.items.childHeader.structure[0].worksheet && section.items.childHeader.structure[0].multipleTexts !== true && 
                            <>
                              {section.items.childHeader.structure[0].items.map((item, index) =>
                                <div key={`${item.itemId}_${index}`} >
                                  <CourceMainContent item={item} index={index} setFieldValue={setFieldValue} showQuestion={(section.items.childHeader.structure[0].defaultHidden !== true || showQuestion || section.items.childHeader.studentSections[0].state !== 1)} isEditableForm={isEditableForm()} studentSections={section.items.childHeader.studentSections} showConvergence={showConvergence()} showDisableConvergence={showDisableConvergence()} values={values} section={section.items.childHeader}/>
                                </div>
                              )}
                              {section.items.childHeader.structure[0].buttons && 
                                <>
                                  {section.items.childHeader.structure[0].buttons.map((button, index) =>
                                    <div key={`btn_${index}`}>
                                      {(isEditableForm() || showConvergence(true)) && (button.type === 'buttonConfirm' || button.type === 'buttonTest') && 
                                        <div className="student-course-row3" style={{textAlign: "right"}}>
                                          {(button.type === 'buttonTest') && 
                                            <button type="submit" disabled={(loading) ? true : false} className="submitLink" onClick={() => setFieldValue(`test_answers`, 1)}>{loading && <span className="spinner-border spinner-border-sm mr-1"></span>}{button.label}</button>
                                          }
                                          {(button.type === 'buttonConfirm') && 
                                            <button type="submit" disabled={(loading) ? true : false} className="submitLink">{loading && <span className="spinner-border spinner-border-sm mr-1"></span>}{button.label}</button>
                                          }
                                        </div>
                                      }
                                    </div>
                                  )}
                                </>
                              }
                            </>
                          }
                          {section.items.childHeader.structure[0].worksheet && 
                            <>
                              <h4>{section.items.childHeader.structure[0].title}</h4>
                              <WorkSheet worksheet={section.items.childHeader.structure[0].worksheet} isEditableForm={isEditableForm()} values={values} studentSections={section.items.childHeader.studentSections} setFieldValue={setFieldValue} buttons={section.items.childHeader.structure[0].buttons} loading={loading} showConvergence={showConvergence} showDisableConvergence={showDisableConvergence()}/>
                            </>
                          }
                          {!section.items.childHeader.structure[0].worksheet && section.items.childHeader.structure[0].multipleTexts === true && 
                            <>
                              <div className="stdcrs-left-cont">
                                <div className="stdcrslft-cont-inner">
                                  <ul>
                                    {section.items.childHeader.structure[0].items.map((item, index) =>
                                      {
                                        if(multiTextShowItem === 0)
                                        {
                                          setMultiTextShowItem(item.itemId);
                                        }
                                        return(
                                          <li key={`link_${index}`} className={`${(multiTextShowItem === item.itemId) ? "acive" : ""}`} style={{cursor:'pointer'}} onClick={() => setMultiTextShowItem(item.itemId)}>{item.title}</li>
                                        )
                                      }
                                    )}
                                  </ul>
                                </div> 
                              </div>
                              <div className="stdcrs-right-ques">
                                <div className="stdcrsrgt-ques-inner">
                                  {section.items.childHeader.structure[0].items.map((item, index) =>
                                    <div key={`${item.itemId}_${index}`} >
                                      <CourceMainContent item={item} index={index} setFieldValue={setFieldValue} showQuestion={((section.items.childHeader.structure[0].defaultHidden !== true || showQuestion || section.items.childHeader.studentSections[0].state !== 1) && multiTextShowItem === item.itemId)} isEditableForm={isEditableForm()} studentSections={section.items.childHeader.studentSections} showConvergence={showConvergence()} showDisableConvergence={showDisableConvergence()} values={values} section={section.items.childHeader}/>
                                    </div>
                                  )}
                                </div>
                              </div>
                              {section.items.childHeader.structure[0].buttons && 
                                <>
                                  {section.items.childHeader.structure[0].buttons.map((button, index) =>
                                    <div key={`btn_${index}`}>
                                      {(isEditableForm() || showConvergence(true)) && (button.type === 'buttonConfirm' || button.type ===  'buttonTest' || 1) && 
                                        <div className="student-course-row3">
                                          {(button.type === 'buttonTest') && 
                                            <button type="submit" disabled={(loading) ? true : false} className="submitLink" onClick={() => setFieldValue(`test_answers`, 1)}>{loading && <span className="spinner-border spinner-border-sm mr-1"></span>}{button.label}</button>
                                          }
                                          {(button.type === 'buttonConfirm') && 
                                            <button type="submit" disabled={(loading) ? true : false} className="submitLink">{loading && <span className="spinner-border spinner-border-sm mr-1"></span>}{button.label}</button>
                                          }
                                          {(button.type === 'buttonShow') && 
                                            <div className="stdcrslft-btn">
                                              <a href="/#" onClick={(e) => handleDisplayQuestions(e, button.type, setFieldValue, values)}>{button.label}</a>
                                            </div>
                                          }
                                        </div>
                                      }
                                    </div>
                                  )}
                                </>
                              }
                            </>
                          }
                        </>
                      }
                    </div>
                    {section.items.childHeader.expected_convergence === 1 && (showConvergence() || showDisableConvergence()) &&
                      <SectionConvergence section={section.items.childHeader} showDisableConvergence={showDisableConvergence()} studentSections={section.items.childHeader.studentSections} loading={loading}/>
                    }
                    </Form>
                  )}
                </Formik>
              </>
            }
          </div>
        </div>
      </div>
    );
}
export default CourseInterface;
