import React from 'react';
import { Field } from "formik";
import Moment from 'moment';
import { useSelector } from 'react-redux';

function QuestionAnswer({ item, index, setFieldValue, isEditableForm, studentSections, values, section }) {
    const student = useSelector(state => state.authentication.user);
    let studentSectionState = 1;

    const displayCorrectAns = () => {
        if(studentSections && studentSections[0])
        {
            studentSectionState = studentSections[0].state;
        }

        if(section && (section.training === null || section.training === '') && studentSectionState !== 1 && student)
        {
            return false;
        }
        else
        {
            return true;
        }
    }

    const isSelectedAns = (answerId, itemId) => {
        if(studentSections && studentSections[0] && studentSections[0].studentAnswers)
        {
            let isMatch = false;
            for (let index = 0; index < studentSections[0].studentAnswers.length; index++) {
                const element = studentSections[0].studentAnswers[index];
                if(element.itemId === itemId && element.answerId === answerId)
                {
                    isMatch = true;
                    break;
                }
            }
            return isMatch;
        }
        return false;
    }
    return (
        <div className="form-group">                       
            <span className="qsn-label">{item.title}</span>
            {item.answers.map((ans, i) =>
                <div key={`${ans.itemId}_${ans.answerId}_${i}`} className={`radio ${(displayCorrectAns()) ? (ans.is_correct === 1) ? 'clr-green' : (isSelectedAns(ans.answerId, item.itemId)) ? 'clr-red' : 'clr-blue' : 'clr-blue'}`}>
                    {isEditableForm && 
                        <Field type="radio" id={`answer_${item.itemId}_${ans.answerId}`} name={`answers.${item.itemId}.answerId`} value={`${ans.answerId}`} required={!item.notMandatory} onClick={()=>{setFieldValue(`answers.${item.itemId}.itemId`, item.itemId); setFieldValue(`answers.${item.itemId}.item_type`, item.typeItem.typeItem);setFieldValue('logs.job.' + values.logs.job.length, {itemId: item.itemId, time: Moment().format('YYYY-MM-DD hh:mm:ss')})}} />
                    }
                    {!isEditableForm && 
                        <Field type="radio" id={`answer_${item.itemId}_${ans.answerId}`} name={`answers.${item.itemId}.${i}.answerId`} value={`${ans.answerId}`} disabled checked={(isSelectedAns(ans.answerId, item.itemId) || (ans.is_correct === 1 && displayCorrectAns())) ? true : false}/>
                    }
                    <label htmlFor={`answer_${item.itemId}_${ans.answerId}`} className="radio-label">{ans.answer}</label>
                    {!isEditableForm && ans.is_correct === 1 && 
                        <span className="text-muted">{item.note}</span>
                    }
                </div>
            )}
        </div>
    );
}

export { QuestionAnswer };